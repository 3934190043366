<template>
  <v-dialog
    v-model="dialogVisible"
    persistent
    :max-width="productAvailable ? '700' : '400'"
  >
    <v-card>
      <v-card-title
        class="primary--text font-weight-bold"
      >
      </v-card-title>
      <v-card-text>
        <p
          class="font-weight-bold text-center"
          v-html="productAvailable
            ? `${this.$t('productAlertMessage')}<br><br>${this.$i18n.getLocaleMessage('fr').productAlertMessage}`
            : this.$t('languageChangeNotification')"
        >
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="font-weight-bold mr-0"
          @click="onContinue"
        >
          {{ this.$t('okay') }}
        </v-btn>
        <v-btn
          v-if="!productAvailable"
          color="primary"
          class="font-weight-bold mr-0"
          @click="onCancel"
        >
          {{ this.$t('cancel') }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      show: {
        type: Boolean,
        required: true,
      },
      lang: {
        type: String,
        required: true,
      },
      productAvailable: {
        type: Boolean,
        required: false,
      },
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data () {
      return {
        dialogVisible: this.show,
        currentLang: this.lang
      }
    },
    watch: {
      show (newValue) {
        this.dialogVisible = newValue
      }
    },
    mounted () {
      const getLangStorage = this.$Storage.getCookie('locale')
      if (getLangStorage) {
        this.currentLang = this.$i18n.locale = getLangStorage
      }
      this.dialogVisible = false
      console.log('VueI18n', this.$i18n.getLocaleMessage('fr').productAlertMessage)
    },
    methods: {
      onContinue () {
        this.$Storage.setCookie('locale', this.$store.getters.getLanguage)
        this.$store.commit('setLanguage', this.$store.getters.getLanguage)
        if (this.productAvailable) {
          window.location.href = '/'
        } else {
          window.location.href = '/canadianterm/survey/pre-application'
        }
      },
      onCancel () {
        this.dialogVisible = false
        this.currentLang = this.currentLang === 'en' ? 'fr' : 'en'
        this.$emit('cancelTrigger', 'cancel trigger')
      }
    },
  }
</script>
<style scoped>
  .v-card__title{
    word-break: break-word!important
  }
</style>

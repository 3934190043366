const ReveChat = {
  install (Vue, options) {
    if (window.location.hostname === 'www.blanket.com') {
      if (!window.$_REVECHAT_API) {
        (function (d, w) {
          const r = (window.$_REVECHAT_API = function (c) {
            r._.push(c)
          })
          w.__revechat_account = options?.accountId
          w.__revechat_version = 2
          r._ = []
          const rc = d.createElement('script')
          rc.type = 'text/javascript'
          rc.async = true
          rc.setAttribute('charset', 'utf-8')
          rc.src = (document.location.protocol === 'https:' ? 'https://' : 'http://') + 'static.revechat.com/widget/scripts/new-livechat.js?' + new Date().getTime()
          const s = d.getElementsByTagName('script')[0]
          s.parentNode.insertBefore(rc, s)
        })(document, window)
      }
    }
  }
}
export default ReveChat

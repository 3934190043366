import store from './store/store'; // Adjust the path according to your directory structure

(function () {
  // Save the original `XMLHttpRequest` object
  const originalXHR = window.XMLHttpRequest

  // Create a new constructor to override `XMLHttpRequest`
  function newXHR () {
    const realXHR = new originalXHR()

    // Intercept open method to capture request details
    realXHR.open = function (...args) {
      this._method = args[0]
      this._url = args[1]
      return originalXHR.prototype.open.apply(this, args)
    }

    // Intercept send method to handle the response
    realXHR.send = function (...args) {
      if (this.timeout === 0) { this.timeout = 3000 }
      const handleResponse = () => {
        if (this.readyState === 4) { // readyState 4 means the request is done
          // Here you can modify the response if needed
          // For example, you can parse and modify JSON response:
          try {
            const jsonResponse = JSON.parse(this.responseText)
          } catch (e) {
          }
        }
      }

      // Monitor state changes and check response
      this.addEventListener('readystatechange', handleResponse)
      this.addEventListener('error', () => {
        // store.commit('setError', 'Something went wrong, Please try again!')
      })
      this.addEventListener('timeout', () => {
       store.commit('setError', 'Something went wrong, Please try again!')
      })

      this.addEventListener('abort', () => {
       // store.commit('setError', 'Something went wrong, Please try again!')
      })

      return originalXHR.prototype.send.apply(this, args)
    }

    return realXHR
  }

  // Override the global `XMLHttpRequest` object with the new one
  window.XMLHttpRequest = newXHR

  const originalFetch = window.fetch
  window.fetch = function (...args) {
    const fetchRequest = args[0]
    const method = args[1]?.method || 'GET'
    return originalFetch.apply(this, args).then(
      (response) => {
        if (!response.ok) {
        }

        return response
      },
      (error) => {
        store.commit('setError', 'Something went wrong, Please try again!')
        return Promise.reject(error)
      }
    )
  }
  window.addEventListener('online', () => {
    store.commit('setNetworkError', false)
  })
  window.addEventListener('offline', () => {
    store.commit('setNetworkError', true)
  })
})()

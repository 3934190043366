import moment from 'moment'
import { getField } from 'vuex-map-fields'
export default {
  getPetBillingPreview (state) {
    return state.petBillingPreview
  },
  getloginerror (state) {
    return state.loginerror
  },
  getuser (state) {
    return state.user
  },
  getLoggedInUser (state) {
    return state.loggedInUser
  },
  getField,
  userprofile (state) {
    return state.userprofile
  },
  currentuserprofile (state) {
    return state.userprofile
  },
  postcodedata (state) {
    return state.postcodedata
  },
  getapplieddetails (state) {
    return state.applieddetails
  },
  getcurrentquote (state) {
    return state.quote
  },
  getDefaultAnicoTermLifeInterview (state) {
    return state.anicoTermlifeInterview
  },
  getbmi (state) {
    return state.bmi
  },
  getnic (state) {
    return state.nic
  },
  getgender (state) {
    return state.gender
  },
  getage (state) {
    return state.age
  },
  getincome (state) {
    return state.income
  },
  getfollowup (state) {
    return state.followup
  },
  getTermLifeRates (state) {
    return state.termLifeRates
  },
  getusertermlifeclass (state) {
    console.log('USER CLASS IS ', state.usertermlifeclass)
    return state.usertermlifeclass
  },
  getProviderRecommendations (state) {
    return state.providerrecommendations
  },
  getMobileStatus (state) {
    return state.mobilestatus
  },
  getEmailStatus (state) {
    return state.emailstatus
  },
  getSymetraFollowUps (state) {
    return state.symetrafollowups
  },
  getCompareList (state) {
    return state.compareList
  },
  getuserpolicies (state) {
    return state.userpolicies
  },
  getTrupanionPolicies (state) {
    return state.petPolicies
  },
  getLoadedCanadianTravelPolicies (state) {
    return state.CanadianTravelPolicies
  },
  getTravelDetails (state) {
    return state.travelDetails
  },
  getUSATravelDetails (state) {
    return state.USATravelDetails
  },
  getTravelInsuranceCanadaQuote (state) {
    return state.travelQuote
  },
  getTravelInsuranceCanadaQuoteFinal (state) {
    return state.canadaQuoteFinalPricing
  },
  getLoadedBlanketPartners (state) {
    return state.blanketpartners
  },
  getLoadedPartnerCampaigns (state) {
    return state.partnerCampaigns
  },
  getDataSpyApplicants (state) {
    return state.DataSpyApplicants
  },
  getPartnerId (state) {
    return state.partnerId
  },
  getCampaignId (state) {
    return state.campaignId
  },
  getInsuredPersons (state) {
    return state.insuredPersons
  },
  getTravelInsurancePlans (state) {
    return state.CanadianTravelInsuranceOffers
  },
  getTravelCoverageDetails (state) {
    return state.travelCoverageDetails
  },
  getCanadianTravelQuote (state) {
    return state.canadaTravelQuote
  },
  getCanadianTravelProducts (state) {
    return state.canadaTravelProducts
  },
  getCanadianTravelPolicy (state) {
    return state.canadaTravelPurchase
  },
  getCanadianTravelPolicies (state) {
    return state.travelpolicies
  },
  getCanadianTravelError (state) {
    return state.canadianTravelError
  },
  getTravelCanadaSurveys (state) {
    return state.canadaTravelSurveys
  },
  getTugoMedicalSurvey (state) {
    return state.tugoMedicalSurvey
  },
  getUSATravelQuote (state) {
    return state.usaTravelQuote
  },
  getTravelUSAPolicy (state) { // the last one sold
    return state.travelUSAPolicy
  },
  getTravelUSAPolicies (state) { // all of them
    return state.usatravelpolicies
  },
  getCFARCost (state) {
    return state.CFARCost
  },
  getRentalDamagePricePref (state) {
    return state.rentalDamagePricePref
  },
  getRentalDamagePriceStd (state) {
    return state.rentalDamagePriceStd
  },
  getGeneraliPolicy (state) {
    return state.generaliPolicy
  },
  getAutoDetails (state) {
    return state.autoDetails
  },
  getPetBreeds (state) {
    return state.petbreeds
  },
  getPetAges (state) {
    return state.petages
  },
  getPetCoverageDetails (state) {
    return state.petCoverageDetails
  },
  getPetDetails (state) {
    return state.petDetails
  },
  getPetPrems (state) {
    console.log('prems are in getter ', state.petPrems)
    return state.petPrems
  },
  getPetPolicy (state) {
    return state.petPolicy
  },
  getPetQuoteResponse (state) {
    return state.petQuoteResponse
  },
  getPets (state) {
    console.log('PETS', state.pets)
    return state.pets
  },
  getEligibility (state) {
    return state.eligibility
  },
  getEligibilityData (state) {
    return state.eligibilityData
  },
  getEligibilityDetails (state) {
    return state.eligibilityDetails
  },
  getTermLifeQuestions (state) {
    return state.termquestions
  },
  getSymetraPdfs (state) {
    return state.symetraPdfFiles
  },
  getSymetraICC20LA1 (state, payload) {
    return state.symetraICC20LA1
  },
  getSymetraICC20LA2 (state, payload) {
    return state.symetraICC20LA2
  },
  getSymetraICC20LA3 (state, payload) {
    return state.symetraICC20LA3
  },
  getSymetraLU820 (state, payload) {
    return state.symetraLU820
  },
  getSymetraAppQuestions (state) {
    return state.symetraAppQuestions
  },
  getSymetraOccupations (state) {
    return state.symetraOccupations
  },
  getSymetraMedicalFollowups (state) {
    return state.symetraMedicalFollowups
  },
  getAnicoAppQuestions (state) {
    return state.anicoAppQuestions
  },
  usergroups (state) {
    if (state.user.status === 'admin') {
      return state.usergroups
    } else {
      let filter = state.usergroups.filter(group => group.name === state.user.group)
      return filter
    }
  },
  alerts (state) {
    return state.alerts
  },
  getloading (state) {
    return state.isloading
  },
  getWOPRates (state) {
    return state.woprates
  },
  getADBRates (state) {
    return state.adbrates
  },
  getICBRates (state) {
    return state.icbrates
  },
  getPetInfoData (state) {
    return state.petInfoData
  },
  getPetPlanData (state) {
    return state.petPlanData
  },
  getTrupanionPaymentParams (state) {
    return state.trupanionPaymentParams
  },
  getCompletedSymetraPDFApplication (state) {
    return state.completedSymetraPDFApplication
  },
  getReadyforSignature (state, payload) {
    return state.readyforSignature
  },
  getRenderedApplicationsLoading (state) {
    return state.renderedApplicationsLoading
  },
  getnoticesloading (state) {
    return state.pdfloading
  },
  getCompletedClientloading (state) {
    return state.completedPdfLoading
  },
  getSymetraCCompletedClientPdfs (state) {
    return state.symetraCompletedClientPdfs
  },
  userdisplayname (state) {
    return state.userdisplay
  },
  userdisplaybio (state) {
    return state.userbio
  },
  userdisplaylevel (state) {
    return state.userlevel
  },
  getPurchaseErrors (state) {
    return state.purchaseerror
  },
  getcurrentuserkey (state) {
    return state.currentUserKey
  },
  userdisplaypoints (state) {
    return state.userpoints
  },
  userdisplaystatus (state) {
    return state.userstatus
  },
  user (state) {
    return state.user
  },
  activeUser (state) {
    return state.activeUser
  },
  loading (state) {
    return state.loading
  },
  error (state) {
    return state.error
  },
  getErrors (state) {
    console.log('getting error ', state.error)
    return state.error
  },
  policyQuarterSummary (state) {
    return state.policyQuarterSummary
  },
  policyTypeSummary (state) {
    return state.policyTypeSummary
  },
  salesByType (state) {
    return state.salesByType
  },
  totalSales (state) {
    return state.totalSales
  },
  getWebsiteLocation (state) {
    return state.websiteLocation
  },
  getUserIp (state) {
    return state.userIp
  },
  getLanguage (state) {
    return state.lang
  },
  getPrefferedLang (state) {
    return state.prefferedLang
  },
  getPageLoaded (state) {
    return state.pageLoaded
  },
  getIsPolicyPurchased (state) {
    return state.isPolicyPurchased
  }
}

const state = {
    homeQuestions: [
        { qId: '375', question: 'Damage currently exists on the property', answers: ['Yes', 'No'], condition: 'If Year Built < current year', kickout: 'Yes', kickoutCase: 'Yes', error: 'Prior Damage Exists', followUps: 'No' },

        { qId: '525', question: 'Any unit on the property is occupied by more than 1 family', answers: ['Yes', 'No'], condition: 'If Year Built < current year', kickout: 'Yes', kickoutCase: 'Yes', error: '"1 or more units occupied by more than 2 families.  Our property insurance agents may be able to provide you with a quote over the phone.  Please contact us at 1-800-566-1518."', followUps: 'No' },

        { qId: '651', question: 'The roof is more than 20 years old', answers: ['Yes', 'No'], condition: 'If "When was the roof updated?" = Updated - year unknown, ask this question.', kickout: 'Yes', kickoutCase: 'Yes', error: 'Roof over 15 years is not eligible.', followUps: 'No' },

        // { qId: '950', question: 'The roof is composed of spray polyurethane', answers: ['Yes', 'No'], condition: 'If Roof Type = Foam or Vinyl, ask this question.', kickout: 'No', error: null, followUps: 'Yes', subQuestions: [{ question: 'The polyurethane was applied in the last 3 years', answers: ['Yes', 'No'], condition: 'Yes', kickout: 'Yes', kickoutCase: 'No', error: 'Polyurethane not applied in last 3 years.' }, {question: 'The roof has less than 1 inch of rise for every 12 inches of run (<1:12 ratio)', answers: ['Yes', 'No'], condition: 'If Roof Type = Foam or Vinyl, ask this question.', kickout: 'Yes', kickoutCase: 'No', error: null }],

        { qId: '1055', question: 'The roof has existing damage', answers: ['Yes', 'No'], condition: 'If Year Built < current year', kickout: 'Yes', kickoutCase: 'Yes', error: 'Roof Condition Unacceptable.  Our property insurance agents may be able to provide you with a quote over the phone.  Please contact us at 1-800-566-1518.', followUps: 'No' },

        { qId: '1820', question: 'The property has a wood burning stove', answers: ['Yes', 'No'], condition: 'If Year Built < current year', followUps: 'Yes', tooltip: true, tooltipMessage: 'We allow a heating source that burns solid fuel in the home provided that: 1) it was professionally installed, and 2) it is not the primary source of heating. These heating sources include stoves, furnaces, fireplace inserts and any similar appliance that burns wood, coal, pellets or other solid fuel. If you select this item, there is an extra charge for these heating sources. Do not select this item if the residence has a built-in fireplace with metal or masonry chimney', subQuestionsCheck: [{ qId: '18200', question: 'The wood burning stove was not installed by a professional', answers: ['Yes', 'No'], condition: 'If q1820 is Yes', kickout: 'Yes', kickoutCase: 'Yes', error: 'Solid fuel burning appliance is ineligible.' }, { qId: '18201', question: 'The wood burning stove is the primary source of heat', answers: ['Yes', 'No'], condition: 'If q1820 is Yes', kickout: 'Yes', kickoutCase: 'Yes', error: 'Solid fuel burning appliance is ineligible.' }, { qId: '18202', question: 'None of the above', clear: true, answers: ['Yes', 'No'], condition: 'If q1820 is Yes', kickout: 'No', kickoutCase: null }] },

        { qId: '1970', question: 'The occupant of the dwelling owns or plans on owning one or more dogs', answers: ['Yes', 'No'], condition: 'Mandatory', followUps: 'Yes', subQuestionsCheck: [{ qId: '19700', question: 'The dog(s) have bitten or injured someone', answers: ['Yes', 'No'], condition: 'If q1970 is Yes', kickout: 'Yes', kickoutCase: 'Yes', error: 'One or more dogs with bite history in household.' }, { qId: '19701', question: ' The dog(s) are a breed (or mix breed) included in this list', tooltip: true, tooltipMessage: 'Akita, Alaskan Malamute, American Staffordshire Terriers, Chow, Dalmatian, Doberman Pinscher, Husky, Pit Bull, Presa Canario (Canary dog), Rottweiler, Staffordshire Bull Terriers, Wolf-dogs, Wolf hybrids', answers: ['Yes', 'No'], condition: 'If q1970 is Yes', kickout: 'Yes', kickoutCase: 'Yes', error: ' Ineligible dog breed in the household.' }, { qId: '19702', question: 'The service dog is required because of a disability defined by the ADA', answers: ['Yes', 'No'], condition: 'If q1820 is Yes', kickout: 'Yes', kickoutCase: 'Yes', error: 'Based on the information provided, this risk is subject to underwriting approval. Please contact underwriting@stillwater.com' }, { qId: '19703', question: 'None of the above', clear: true, answers: ['Yes', 'No'], condition: 'If q1820 is Yes', kickout: 'No', kickoutCase: null }] },

        { qId: '2200', question: 'The dwelling is located within 400 ft. of brush area, forested area or any area of increased hazard', answers: ['Yes', 'No'], condition: 'If Year Built < current year', kickout: 'Yes', kickoutCase: 'Yes', error: 'Near Brush Area or Forest Area.', followUps: 'No' },

        { qId: '2256', question: 'This property is vacant', answers: ['Yes', 'No'], condition: 'Mandatory', kickout: 'Yes', kickoutCase: 'Yes', error: 'House is Vacant.', followUps: 'No' },

        { qId: '2350', question: 'The electrical panel contains fuses', answers: ['Yes', 'No'], condition: 'If Year Built < current year', kickout: 'Yes', kickoutCase: 'Yes', error: 'House has Fuses.Our property insurance agents may be able to provide you with a quote over the phone.  Please contact us at 1-800-566-1518.', followUps: 'No' },

        { qId: '2801', question: 'Abondoned  or non-operational vehicles on property', answers: ['Yes', 'No'], condition: 'If Year Built < current year', kickout: 'Yes', kickoutCase: 'Yes', error: 'Abandoned, non-operational or commercial vehicles on premises.', followUps: 'No' },

        { qId: '3052', question: 'A business is run on the property which entails foot traffic', answers: ['Yes', 'No'], condition: 'Mandatory', kickout: 'Yes', kickoutCase: 'Yes', error: 'Business being run on Property.  Our property insurance agents may be able to provide you with a quote over the phone.  Please contact us at 1-800-566-1518.', followUps: 'No' },

        { qId: '3245', question: 'There is a swimming pool on the property', answers: ['Yes', 'No'], condition: 'Mandatory', kickout: 'No', kickoutCase: null, error: null, followUps: 'Yes', subQuestionsCheck: [{ qId: '32450', question: 'One or more of the swimming pools is not enclosed', answers: ['Yes', 'No'], condition: 'Yes', kickout: 'Yes', kickoutCase: 'Yes', error: 'Swimming Pool does not have a Fence. Our property insurance agents may be able to provide you with a quote over the phone.  Please contact us at 1-800-566-1518.' }, { qId: '32451', question: 'One or more of the swimming pools is unfenced, unfilled or unmaintained', answers: ['Yes', 'No'], condition: 'If q3245 = yes', kickout: 'Yes', kickoutCase: null, error: 'Swimming Pool does not have a Fence.Our property insurance agents may be able to provide you with a quote over the phone.  Please contact us at 1-800-566-1518.' }], subQuestionsSelect: [{ qId: '32452', question: 'One or more of the swimming pools has a diving board or a slide', answers: ['Diving Board Only', 'Slide Only', 'Diving Board and Slide', 'No Diving Board or Slide'], condition: 'If q3245 = yes', kickout: 'Yes', kickoutCases: ['Diving Board Only', 'Diving Board and Slide'], error: 'Pool has diving board or slides. Our property insurance agents may be able to provide you with a quote over the phone.  Please contact us at 1-800-566-1518.' }] },

        { qId: '3502', question: 'There has been one or more home insurance claims in the past five years', answers: ['Yes', 'No'], condition: 'If Year Built < current year', kickout: 'No', kickoutCase: null, error: null, followUps: 'Yes', subQuestionsText: [{ qId: '35020', question: 'How many Liability Related Losses?', kickout: 'Yes', kickoutCase: 1, error: 'Number/type of losses does not qualify.' }, { qId: '35021', question: 'How many Water Related Losses?', kickout: 'Yes', kickoutCase: 1, error: 'Number/type of losses does not qualify.' }, { qId: '35022', question: 'How many Weather Related Losses?', kickout: 'Yes', kickoutCase: 2, error: 'Number/type of losses does not qualify.' }, { qId: '35023', question: 'How many Other Losses?', kickout: 'Yes', kickoutCase: 2, error: 'Number/type of losses does not qualify.' }] },

        { qId: '3907', question: 'There is a trampoline on the property that is not located in a fenced portion of the yard OR is not equipped with a safety net', answers: ['Yes', 'No'], condition: 'Mandatory', kickout: 'Yes', kickoutCase: 'Yes', error: 'The trampoline exposure is ineligible.', followUps: 'No' },

        { qId: '4002', question: 'The property is used for farming or ranching Farming/Ranching equipment is stored on the property', answers: ['Yes', 'No'], condition: 'If Year Built < current year', kickout: 'Yes', kickoutCase: 'Yes', error: 'Outbuildings, farm/ranch equipment, farm animals, and/or exotic pets do not fit guidelines.', followUps: 'No' },

        { qId: '0001', question: 'The home has unusual or irreplaceable construction', answers: ['Yes', 'No'], condition: 'If Year Built < current year', kickout: 'Yes', kickoutCase: 'Yes', error: 'Our property insurance agents may be able to provide you with a quote over the phone.  Please contact us at 1-800-566-1518.', followUps: 'No' },

        { qId: '0002', question: 'The home is log, mobile, manufactured or metal home', answers: ['Yes', 'No'], condition: 'If Year Built < current year', kickout: 'Yes', kickoutCase: 'Yes', error: 'Our property insurance agents may be able to provide you with a quote over the phone.  Please contact us at 1-800-566-1518.', followUps: 'No' },

        { qId: '0003', question: 'The home is on posts, stilts or pilings', answers: ['Yes', 'No'], condition: 'If Year Built < current year', followUps: 'Yes', subQuestionsCheck: [{ qId: '00030', question: 'This is NOT required or authorized by current building codes to be elevated and there IS slope/stilts', answers: ['Yes', 'No'], condition: '0003 is Yes', kickout: 'Yes', kickoutCase: 'Yes', error: 'Based on the information provided, this risk does not qualify. Dwelling is on posts, stilts or pilings' }] },

        { qId: '0004', question: 'The home has asbestos siding or roofing', answers: ['Yes', 'No'], condition: 'If Year Built < current year', kickout: 'Yes', kickoutCase: 'Yes', error: 'Our property insurance agents may be able to provide you with a quote over the phone.  Please contact us at 1-800-566-1518.', followUps: 'No' },

    ]
}

const getters = {
    getStillwaterCaliforniaQuestions (state) {
      return state.CaQuestions
    },
    }

      export default {
        state,
        getters
      }

import { firebase } from '@firebase/app'
import '@firebase/database'
import '@firebase/storage'
import 'firebase/functions'
import 'firebase/auth'
import moment from 'moment'

const state = {
  condoDwellUses: '',
  condoPropertyData: '',
  condoOwner: '',
  smallCondoQuote: '',
  smallCondoQuoteNumber: '',
  mediumCondoQuote: '',
  mediumCondoQuoteNumber: '',
  largeCondoQuote: '',
  largeCondoQuoteNumber: '',
  condoQuote: '',
  condoSurveyQuestions: '',
  condoDiscountQuestions: '',
  customCondoQuote: '',
  customCondoQuoteNumber: '',
  condoDiscounts: '',
  condoQuoteWarning: '',
  condoQuoteNumber: '',
  condoDocuments: '',
  condoPolicy: '',
  condoPolicies: '',
}

const getters = {
  getCondoPropertyData (state) {
    return state.condoPropertyData
  },
  getCondoOwner (state) {
    return state.condoOwner
  },
  getSmallCondoQuote (state) {
    return state.smallCondoQuote
  },
  getSmallCondoQuoteNumber (state) {
    return state.smallCondoQuoteNumber
  },
  getMediumCondoQuote (state) {
    return state.mediumCondoQuote
  },
  getMediumCondoQuoteNumber (state) {
    return state.mediumCondoQuoteNumber
  },
  getLargeCondoQuote (state) {
    return state.largeCondoQuote
  },
  getLargeCondoQuoteNumber (state) {
    return state.largeCondoQuoteNumber
  },
  getCondoQuote (state) {
    return state.condoQuote
  },
  getCondoQuoteNumber (state) {
    return state.condoQuoteNumber
  },
  getCondoSurveyQuestions (state) {
    return state.condoSurveyQuestions
  },
  getCondoDiscountQuestions (state) {
    return state.condoDiscountQuestions
  },
  getCustomCondoQuote (state) {
    return state.customCondoQuote
  },
  getCustomCondoQuoteNumber (state) {
    return state.customCondoQuoteNumber
  },
  getCondoDiscounts (state) {
    return state.condoDiscounts
  },
  getCondoQuoteWarning (state) {
    return state.condoQuoteWarning
  },
  getCondoDocuments (state) {
    return state.condoDocuments
  },
  getCondoPolicy (state) {
    return state.condoPolicy
  },
  getCondoPolicies (state) {
    return state.condoPolicies
  },
}
const mutations = {
  setCondoPropertyData (state, payload) {
    state.condoPropertyData = payload
  },
  setCondoOwner (state, payload) {
    state.condoOwner = payload
  },
  setSmallCondoQuote (state, payload) {
    state.smallCondoQuote = payload
  },
  setSmallCondoQuoteNumber (state, payload) {
    state.smallCondoQuoteNumber = payload
  },
  setMediumCondoQuote (state, payload) {
    state.mediumCondoQuote = payload
  },
  setMediumCondoQuoteNumber (state, payload) {
    state.mediumCondoQuoteNumber = payload
  },
  setLargeCondoQuote (state, payload) {
    state.largeCondoQuote = payload
  },
  setLargeCondoQuoteNumber (state, payload) {
    state.largeCondoQuoteNumber = payload
  },
  setCondoQuote (state, payload) {
    state.condoQuote = payload
  },
  setCondoQuoteNumber (state, payload) {
    state.condoQuoteNumber = payload
  },
  setCondoSurveyQuestions (state, payload) {
    state.condoSurveyQuestions = payload
  },
  setCondoDiscountQuestions (state, payload) {
    state.condoDiscountQuestions = payload
  },
  setCustomCondoQuote (state, payload) {
    state.customCondoQuote = payload
  },
  setCustomCondoQuoteNumber (state, payload) {
    state.customCondoQuoteNumber = payload
  },
  setCondoDiscounts (state, payload) {
    state.condoDiscounts = payload
  },
  setCondoQuoteWarning (state, payload) {
    state.condoQuoteWarning = payload
  },
  setCondoDocuments (state, payload) {
    state.condoDocuments = payload
  },
  setCondoPolicy (state, payload) {
    state.condoPolicy = payload
  },
  setCondoPolicies (state, payload) {
    state.condoPolicies = payload
  },
  setCondoOwnerSSN (state, payload) {
    state.condoOwner.ssn = payload
  }
}
const actions = {
  setCondoOwnerSSN ({ commit }, payload) {
    commit('setCondoOwnerSSN', payload)
  },
  setCondoPropertyData ({ commit }, payload) {
    commit('setCondoPropertyData', payload)
  },
  async getH6CondoQuestions ({ commit, getters }, payload) {
    try {
      const { propertyType, state } = payload
      const use = getters.getCondoOwner.dwellUse.txt

      // reset
      commit('setCondoSurveyQuestions', [])

      const data = await firebase
        .database()
        .ref('stillwater/questions')
        .child(state)
        .child('H6')
        .child(propertyType)
        .once('value')

      const values = Object.values(data.val())
      const questions = values.filter((value) => value.dwell.txt === use)

      console.log('CONDO QUESTIONS', questions)
      commit('setCondoSurveyQuestions', questions)
    } catch (error) {
      console.log(error)
    }
  },
  async getH6DiscountQuestions ({ commit, getters }, payload) {
    try {
      console.log(payload)
      const { propertyType, state } = payload
      const use = getters.getCondoOwner.dwellUse.txt
      console.log('use', use)
      // reset
      commit('setCondoDiscountQuestions', [])

      const data = await firebase
        .database()
        .ref('stillwater/discount')
        .child(state)
        .child('H6')
        .child(propertyType)
        .once('value')

      const values = Object.values(data.val())
      const discounts = values.filter((value) => value.dwell.txt === use)

      console.log('CONDO DISCOUNTS', discounts)
      commit('setCondoDiscountQuestions', discounts)
    } catch (error) {
      console.log(error)
    }
  },
  setCondoOwner ({ commit }, payload) {
    commit('setCondoOwner', payload)
  },
  setCondoQuote ({ commit }, payload) {
    commit('setCondoQuote', payload)
  },

  async propertyLookupCondo ({ commit, getters }, payload) {
    commit('setLoading', true)
    let rec = {
      signonRq: {
        custLangPref: 'ENG',
      },
      addr: {
        addr1: '1009 W Saint Charles Ave',
        city: 'Phoenix',
        stateProvCd: 'AZ',
        postalCode: '85041',
      },
    }
    console.log(rec)
    let propertyLookup = firebase.functions().httpsCallable('propertyLookup')
    return propertyLookup(rec)
      .then((data) => {
        let resp = data.data.response.data
        console.log('Stillwater property lookup response ', resp)
        console.log(resp)
        console.log(
          'Year Built',
          resp.ACORD.InsuranceSvcRs[0]['com.stillwater_PropertyDataRs'][0]
            .HomeLineBusiness[0].Dwell[0].Construction[0].YearBuilt[0]
        )
        console.log('')
        commit('setCondoPropertyData', resp)
      })
      .catch((error) => {
        console.log(error)
        commit('setLoading', false)
      })
  },
  async getInitialCondoQuote ({ commit }, payload) {
    console.log('Calling initial condo quote', payload)
    commit('setLoading', true)

    const { owner } = payload
    const mailingAddress = owner.mailingAddress
    const previousAddress = owner.previousAddress
    const nonCA = owner.nonCA
    const use = isNaN(owner.dwellUse.value)
      ? owner.dwellUse.value
      : parseInt(owner.dwellUse.value)
    const propCode = owner.propertyCode
    // if business owned
    const dwellOccupancy = owner.corporationOwnerName
      ? {
          residenceTypeCd: propCode,
          dwellUseCd: use,
          occupancyTypeCd: 'OWNER',
          isCorporationOwned: 'YES',
          corporationOwnerName: owner.corporationOwnerName,
          corporationOwnerPhone: owner.corporationOwnerPhone,
        }
      : {
          residenceTypeCd: propCode,
          dwellUseCd: use,
          occupancyTypeCd: 'OWNER',
        }
    // if customer owned
    let generalPartyInfo = {
      communications: owner.communications,
      addresses: [],
    }

    if (!owner.corporationOwnerName) {
      generalPartyInfo.nameInfo = {
        personName: {
          surname: owner.lastname,
          givenName: owner.firstname,
        }
      }
    }

    if (mailingAddress) {
      generalPartyInfo.addresses.push(mailingAddress)
    }

    if (previousAddress) {
      generalPartyInfo.addresses.push(previousAddress)
    }
    // if hurricane deductible is set
    const baseCoverages = [
      { coverageCd: 'DWELL', limits: [{ formatInteger: 30000 }] },
      {
        coverageCd: 'PP',
        limits: [{ formatInteger: payload.amt }],
        deductible: {
          formatInteger: payload.deductible,
          deductibleAppliesToCd: 'AllPeril',
        },
      },
    ]

    const hurricaneCoverage = payload.hurricaneDeductible
      ? [
          {
            coverageCd: 'HURR',
            deductible: {
              formatInteger: payload.hurricaneDeductible,
              deductibleAppliesToCd: 'HURR',
            },
          },
        ]
      : []

    const coverages = [...baseCoverages, ...hurricaneCoverage]
    let rec = {
      nonCA: nonCA,
      signonRq: {
        custLangPref: 'ENG',
      },
      insuranceSvcRq: {
        quoteOrBuy: 'Q',
        homePolicyRq: {
          curCd: 'USD',
          insuredOrPrincipals: [
            {
              generalPartyInfo: generalPartyInfo,
              insuredOrPrincipalInfo: {
                insuredOrPrincipalRoleCd: 'FNI',
                personInfo: {
                  birthDt: owner.dob,
                },
              },
            },
          ],
          persPolicy: {
            LOBCd: 'HOME',
            contractTerm: {
              effectiveDt: moment().format('YYYY-MM-DD'),
              expirationDt: moment().add(1, 'year').format('YYYY-MM-DD'),
              durationPeriod: {
                numUnits: 12,
                unitMeasurementCd: 'MO',
              },
            },
            persApplicationInfo: {
              residenceOwnedRentedCd: 'OWNED',
            },
          },
          location: {
            id: 'LOC1',
            addr: {
              addrTypeCd: 'PhysicalRisk',
              addr1: owner.address.addr1,
              city: owner.address.city,
              stateProvCd: owner.address.stateProvCd,
              postalCode: owner.address.postalCode,
            },
          },
          homeLineBusiness: {
            LOBCd: 'HOME',
            dwell: {
              id: 'DWE1',
              locationRef: 'LOC1',
              policyTypeCd: '06',
              construction: owner.construction ? owner.construction : {},
              dwellOccupancy: dwellOccupancy,
              coverages: coverages,
            },
          },
        },
      },
    }
    console.log('CONDO QUOTE REC', rec)
    let getCondoQuote = firebase.functions().httpsCallable('rentersQuote')

    return getCondoQuote(rec)
      .then((data) => {
        console.log(data)
        const hasError =
          data.data.response.haserror === undefined
            ? data.data.response[0].haserror
            : data.data.response.haserror
        if (hasError === true) {
          console.log('get condo quote error')
          commit(
            'setError',
            'Unable to process request: ' + data.data.response[0].message
          )
          return
        }
        const response = data?.data?.response
        const insuranceSvcRs = response?.data?.ACORD?.InsuranceSvcRs[0]
        const homePolicyQuoteInqRs = insuranceSvcRs?.HomePolicyQuoteInqRs[0]
        const persPolicy = homePolicyQuoteInqRs?.PersPolicy[0]
        const quoteInfo = persPolicy?.QuoteInfo[0]
        const msgStatusDesc =
          response?.data?.ACORD?.InsuranceSvcRs[0]?.HomePolicyQuoteInqRs[0]
            ?.MsgStatus?.[0]?.MsgStatusDesc?.[0] ?? ''
        console.log(msgStatusDesc)

        const setQuote = (type, value) => {
          commit(
            `set${type.charAt(0).toUpperCase()}${type.slice(1)}CondoQuote`,
            value
          )
        }

        const setQuoteNumber = (type, value) => {
          commit(
            `set${type.charAt(0).toUpperCase()}${type.slice(
              1
            )}CondoQuoteNumber`,
            value
          )
        }

        setQuote(payload.type, response.data)

        if (quoteInfo?.CompanysQuoteNumber) {
          const companysQuoteNumber = quoteInfo.CompanysQuoteNumber[0]
          setQuoteNumber(payload.type, companysQuoteNumber)
        }

        if (msgStatusDesc !== undefined && msgStatusDesc.includes('WARNING')) {
          console.log('warning')
          const warningMessage =
            'WARNING - This risk will be quoted Excluding Wind/Hail coverage. Do not continue if you require Wind/Hail coverage.'
          commit('setCondoQuoteWarning', warningMessage)
        }

        commit('setLoading', false)
      })
      .catch((error) => {
        console.log(error)
        commit('setError', 'Unable to process request condo quote')
        commit('setLoading', false)
      })
  },
  // async getCustomCondoQuote ({ commit, getters }, payload) {
  //   console.log('Calling custom condo quote', payload)
  //   commit('setLoading', true)
  //   let owner = getters.getCondoOwner
  //   let mailingAddress = owner.mailingAddress
  //   let previousAddress = owner.previousAddress
  //   let nonCA = owner.nonCA
  //   let use = parseInt(owner.dwellUse)
  //   let propCode = owner.propertyCode
  //   let amt = payload.amt
  //   let rec = {
  //     nonCA: nonCA,
  //     signonRq: {
  //       custLangPref: 'ENG',
  //     },
  //     insuranceSvcRq: {
  //       quoteOrBuy: 'Q',
  //       homePolicyRq: {
  //         curCd: 'USD',
  //         insuredOrPrincipals: [
  //           {
  //             generalPartyInfo: {
  //               nameInfo: {
  //                 personName: {
  //                   surname: owner.lastname,
  //                   givenName: owner.firstname,
  //                 },
  //               },
  //             },
  //             insuredOrPrincipalInfo: {
  //               insuredOrPrincipalRoleCd: 'FNI',
  //               personInfo: {
  //                 birthDt: owner.dob,
  //               },
  //             },
  //           },
  //         ],
  //         persPolicy: {
  //           LOBCd: 'HOME',
  //           contractTerm: {
  //             effectiveDt: moment().format('YYYY-MM-DD'),
  //             expirationDt: moment().add(1, 'year').format('YYYY-MM-DD'),
  //             durationPeriod: {
  //               numUnits: 12,
  //               unitMeasurementCd: 'MO',
  //             },
  //           },
  //           persApplicationInfo: {
  //             residenceOwnedRentedCd: 'OWNED',
  //           },
  //         },
  //         location: {
  //           id: 'LOC1',
  //           addr: {
  //             addrTypeCd: 'PhysicalRisk',
  //             addr1: owner.address.addr1,
  //             city: owner.address.city,
  //             stateProvCd: owner.address.stateProvCd,
  //             postalCode: owner.address.postalCode,
  //           },
  //         },
  //         homeLineBusiness: {
  //           LOBCd: 'HOME',
  //           dwell: {
  //             id: 'DWE1',
  //             locationRef: 'LOC1',
  //             policyTypeCd: '06',
  //             dwellOccupancy: {
  //               residenceTypeCd: propCode,
  //               dwellUseCd: use,
  //               occupancyTypeCd: 'OWNER',
  //             },
  //             coverages: [
  //               {
  //                 coverageCd: 'DWELL',
  //                 limits: [
  //                   {
  //                     formatInteger: 30000,
  //                   },
  //                 ],
  //               },
  //               {
  //                 coverageCd: 'PP',
  //                 limits: [
  //                   {
  //                     formatInteger: amt,
  //                   },
  //                 ],
  //                 deductible: {
  //                   formatInteger: payload.deductible,
  //                   deductibleAppliesToCd: 'AllPeril',
  //                 },
  //               },
  //             ],
  //           },
  //         },
  //       },
  //     },
  //   }
  //   console.log(rec)
  //   let getCondoQuote = firebase.functions().httpsCallable('condoQuote')
  //   return getCondoQuote(rec)
  //     .then((data) => {
  //       console.log(data)
  //       if (
  //         (data.data.response.haserror === undefined &&
  //           data.data.response[0].haserror === true) ||
  //         (!(data.data.response.haserror === undefined) &&
  //           data.data.response.haserror === true)
  //       ) {
  //         console.log('get condo quote error')
  //         commit(
  //           'setError',
  //           'Unable to process request: ' + data.data.response[0].message
  //         )
  //         commit('setLoading', false)
  //         return
  //       }
  //       let resp = data.data.response.data
  //       commit('setCustomCondoQuote', resp)
  //       commit(
  //         'setCustomCondoQuoteNumber',
  //         resp.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0].PersPolicy[0]
  //           .QuoteInfo[0].CompanysQuoteNumber[0]
  //       )
  //       commit('setLoading', false)
  //     })
  //     .catch((error) => {
  //       commit('setError', 'Unable to process request condo quote')
  //       console.log(error)
  //       commit('setLoading', false)
  //     })
  // },
  // UNDERWRITING QUOTE
  async getCondoQuoteUnderwriting ({ commit, getters }, payload) {
    console.log('Calling underwriting quote', payload)
    commit('setLoading', true)
    const condo = getters.getCondoPropertyData
    const owner = getters.getCondoOwner
    const mailingAddress = owner.mailingAddress
    const previousAddress = owner.previousAddress
    const nonCA = owner.nonCA
    const use = parseInt(owner.dwellUse.value)
    const propCode = owner.propertyCode
    const quote = getters.getCondoQuote
    const ppAmt =
      quote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0].HomeLineBusiness[0]
        .Dwell[0].TotalInsurableReplCostAmt[0].Amt[0]
    const {
      construction,
      bldgImprovements,
      solidFuel,
      bldgProtection,
      dwellInspectionValuation,
      qa,
      purchaseDt,
      losses,
    } = condo
    // Build dwellOccupancy object
    let dwellOccupancy = {
      residenceTypeCd: propCode,
      dwellUseCd: use,
      occupancyTypeCd: 'OWNER',
    }
    if (owner.corporationOwnerName) {
      dwellOccupancy.isCorporationOwned = 'YES'
      dwellOccupancy.corporationOwnerName = owner.corporationOwnerName
      dwellOccupancy.corporationOwnerPhone = owner.corporationOwnerPhone
    }

    // Build generalPartyInfo object
    let generalPartyInfo = {
      communications: owner.communications,
      addresses: [],
    }
    if (!owner.corporationOwnerName) {
      generalPartyInfo.nameInfo = {
        personName: {
          surname: owner.lastname,
          givenName: owner.firstname,
        },
        taxIdentity: {
          taxIdTypeCd: 'SSN',
          taxId: owner.ssn,
        }
      }
    }
    if (mailingAddress) {
      generalPartyInfo.addresses.push(mailingAddress)
    }
    if (previousAddress) {
      generalPartyInfo.addresses.push(previousAddress)
    }
    let rec = {
      nonCA: nonCA,
      signonRq: {
        custLangPref: 'ENG',
      },
      insuranceSvcRq: {
        quoteOrBuy: 'Q',
        homePolicyRq: {
          curCd: 'USD',
          insuredOrPrincipals: [
            {
              generalPartyInfo,
              insuredOrPrincipalInfo: {
                insuredOrPrincipalRoleCd: 'FNI',
                personInfo:
                  payload.maritalStatus !== ''
                    ? {
                        birthDt: owner.dob,
                        maritalStatusCd: payload.maritalStatus,
                      }
                    : {
                        birthDt: owner.dob,
                      },
              },
            },
          ],
          persPolicy: {
            LOBCd: 'HOME',
            contractTerm: {
              effectiveDt: moment().format('YYYY-MM-DD'),
              expirationDt: moment().add(1, 'year').format('YYYY-MM-DD'),
              durationPeriod: {
                numUnits: 12,
                unitMeasurementCd: 'MO',
              },
            },
            persApplicationInfo: {
              residenceOwnedRentedCd: 'OWNED',
            },
            losses: losses,
          },
          location: {
            id: 'LOC1',
            addr: {
              addrTypeCd: 'PhysicalRisk',
              ...owner.address,
            },
          },
          homeLineBusiness: {
            LOBCd: 'HOME',
            dwell: {
              id: 'DWE1',
              locationRef: 'LOC1',
              policyTypeCd: '06',
              purchaseDt: purchaseDt,
              dwellOccupancy,
              construction,
              bldgProtection,
              solidFuel,
              questionAnswers: qa,
              dwellInspectionValuation,
              coverages: [
                {
                  coverageCd: 'DWELL',
                  limits: [
                    {
                      formatInteger: 30000,
                    },
                  ],
                },
                {
                  coverageCd: 'PP',
                  limits: [
                    {
                      formatInteger: ppAmt,
                    },
                  ],
                  deductible: {
                    formatInteger: payload.deductible,
                    deductibleAppliesToCd: 'AllPeril',
                  },
                },
              ],
              ...(bldgImprovements === null
                ? {}
                : {
                    bldgImprovements,
                  }),
            },
          },
        },
      },
    }
    console.log(rec)
    let getCondoQuote = firebase.functions().httpsCallable('rentersQuote')

    return getCondoQuote(rec)
      .then((data) => {
        console.log(data)
        const hasError =
          data.data.response.haserror === undefined
            ? data.data.response[0].haserror
            : data.data.response.haserror
        if (hasError === true) {
          console.log('get condo quote error')
          commit(
            'setError',
            'Unable to process request: ' + data.data.response[0].message
          )
          return
        }

        const discounts = []
        const resp = data.data.response.data
        console.log('RESP DATA', resp)
        const coverages =
          resp.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
            .HomeLineBusiness[0].Dwell[0].Coverage
        console.log('coverages', coverages)

        coverages.forEach((coverage) => {
          if (
            coverage.CurrentTermAmt &&
            coverage.CurrentTermAmt[0].Amt[0].includes('-')
          ) {
            discounts.push(coverage)
            console.log('discount found')
          }
        })

        commit('setCondoDiscounts', discounts)
        console.log(discounts)

        commit('setCondoQuote', resp)

        commit(
          'setCondoQuoteNumber',
          resp.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0].PersPolicy[0]
            .QuoteInfo[0].CompanysQuoteNumber[0]
        )
        console.log(
          'condo quote number',
          resp.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0].PersPolicy[0]
            .QuoteInfo[0].CompanysQuoteNumber[0]
        )
      })
      .catch((error) => {
        commit('setError', 'Unable to process request condo quote')
        console.log(error)
      }).finally(() => {
        commit('setLoading', false)
      })
  },
  // PRE BIND ACTION
  async condoPreBind ({ commit, getters }, payload) {
    const owner = getters.getCondoOwner
    const mailingAddress = owner.mailingAddress
    const previousAddress = owner.previousAddress

    const generalPartyInfo = {
      communications: owner.communications,
      addresses: [
        ...(mailingAddress ? [mailingAddress] : []),
        ...(previousAddress ? [previousAddress] : []),
      ],
      ...(owner.corporationOwnerName
        ? {}
        : {
            nameInfo: {
              personName: {
                surname: owner.lastname,
                givenName: owner.firstname,
              }
            },
          }),
    }

    commit('setLoading', true)
    console.log('Calling condo prebind')

    const requestPayload = {
      birthDt: owner.dob,
    }

    const request = {
      nonCA: owner.nonCA,
      signonRq: { custLangPref: 'ENG' },
      insuranceSvcRq: {
        quoteOrBuy: 'Q',
        homePolicyRq: {
          curCd: 'USD',
          isPreBind: 1,
          insuredOrPrincipals: [
            {
              generalPartyInfo,
              insuredOrPrincipalInfo: {
                insuredOrPrincipalRoleCd: 'FNI',
                personInfo: { ...requestPayload },
              },
            },
          ],
          persPolicy: {
            LOBCd: 'HOME',
            quoteInfo: { companysQuoteNumber: getters.getCondoQuoteNumber },
          },
          location: {
            id: 'LOC1',
            addr: {
              addrTypeCd: 'PhysicalRisk',
              addr1: owner.address.addr1,
              city: owner.address.city,
              stateProvCd: owner.address.stateProvCd,
              postalCode: owner.address.postalCode,
            },
          },
          homeLineBusiness: { LOBCd: 'HOME', dwell: {} },
        },
      },
    }

    console.log(request)
    const condoPreBind = firebase.functions().httpsCallable('rentersPreBind')
    try {
      const data = await condoPreBind(request)
      console.log('Pre Bind data', data)
      const hasError =
        data.data.response.haserror === undefined
          ? data.data.response[0].haserror
          : data.data.response.haserror
      if (hasError === true) {
        console.log('get condo quote error')
        commit(
          'setError',
          'Unable to process request: ' + data.data.response[0].message
        )
      } else {
        console.log('RESULT ', data)
      }
    } catch (error) {
      commit('setError', 'Unable to process request')
      console.log(error)
    } finally {
      commit('setLoading', false)
    }
  },
  async condoBindCC ({ commit, getters }, payload) {
    commit('setLoading', true)
    console.log('Calling condo bind', payload)
    let owner = getters.getCondoOwner
    let billingAddress = payload.addr
    let mailingAddress = owner.mailingAddress
    let previousAddress = owner.previousAddress
    let policyData = getters.getCondoQuote
    let userkey = getters.getuser.id
    const secondOwner = payload.secondOwner
    // If mortgage is true, then add mortgagee to policy

    const insuredOrPrincipals = [
      {
        generalPartyInfo: {
          communications: owner.communications,
          addresses: [
            ...(mailingAddress ? [mailingAddress] : []),
            ...(previousAddress ? [previousAddress] : []),
          ],
          ...(owner.corporationOwnerName
            ? {}
            : {
                nameInfo: {
                  personName: {
                    surname: owner.lastname,
                    givenName: owner.firstname,
                  },
                },
              }),
        },
        insuredOrPrincipalInfo: {
          insuredOrPrincipalRoleCd: 'FNI',
          personInfo: {
            birthDt: owner.dob,
          },
        },
      },
    ]

    if (secondOwner) {
      insuredOrPrincipals.push({
        generalPartyInfo: {
          nameInfo: {
            personName: {
              surname: secondOwner.lastname,
              givenName: secondOwner.firstname,
            },
          },
          addresses: [
            ...(mailingAddress ? [mailingAddress] : []),
            ...(previousAddress ? [previousAddress] : []),
          ],
        },
        insuredOrPrincipalInfo: {
          insuredOrPrincipalRoleCd: 'AI',
          personInfo: {
            birthDt: secondOwner.dob,
          },
        },
      })
    }

    let persApplicationInfo = {
      addr: billingAddress,
      ...(payload.vesting && {
        vestingInfo: { statementLine1: payload.vesting },
      }),
    }

    const location = {
      id: 'LOC1',
      addr: {
        addrTypeCd: 'PhysicalRisk',
        addr1: owner.address.addr1,
        city: owner.address.city,
        stateProvCd: owner.address.stateProvCd,
        postalCode: owner.address.postalCode,
      },
    }

    if (payload.mortgage) {
      location.additionalInterest = payload.mortgage
    }
    let rec = {
      policyData: policyData,
      userkey: userkey,
      nonCA: owner.nonCA,
      signonRq: {
        custLangPref: 'ENG',
      },
      insuranceSvcRq: {
        quoteOrBuy: 'B',
        homePolicyRq: {
          transactionEffectiveDt: moment().format('YYYY-MM-DD'),
          curCd: 'USD',
          insuredOrPrincipals: insuredOrPrincipals,
          persPolicy: {
            LOBCd: 'HOME',
            paymentOption: payload.paymentOption,
            quoteInfo: {
              companysQuoteNumber: getters.getCondoQuoteNumber,
            },
            questionAnswers: [
              {
                qCode: 'com.stillwater_AcknowledgementStatement',
                answer: 'YES',
                id: '100',
              },
            ],
            persApplicationInfo: persApplicationInfo,
          },
          location: location,
          homeLineBusiness: {
            LOBCd: 'HOME',
            dwell: {
              id: 'DWE1',
            },
          },
        },
      },
    }
    console.log('calling condo bind cc', rec)
    let condoBind = firebase.functions().httpsCallable('rentersBind')
    return condoBind(rec)
      .then((data) => {
        if (
          (data.data.response.haserror === undefined &&
            data.data.response[0].haserror === true) ||
          (!(data.data.response.haserror === undefined) &&
            data.data.response.haserror === true)
        ) {
          console.log('get condo quote error')
          commit(
            'setError',
            'Unable to process request: ' + data.data.response[0].message
          )
          commit('setLoading', false)
          return
        }
        console.log('BIND RESULT cc ', data)
        commit('setCondoPolicy', data.data.response.data)
        commit('setLoading', false)
      })
      .catch((error) => {
        commit('setError', 'Unable to process request')
        console.log(error)
        commit('setLoading', false)
      })
  },
  async loadCondoDocuments ({ commit, getters }, payload) {
    try {
      commit('setLoading', true)
      commit('setCondoDocuments', []) // reset

      let qNum = getters.getCondoQuoteNumber
      let pdfUrls = []
      const storageRef = firebase
        .storage()
        .ref()
        .child('stillwater')
        .child('documents')
        .child(qNum)

      let results = await storageRef.listAll()
      if (results.items.length === 0) {
        commit('setError', 'No documents found')
        return
      }

      let promises = results.items.map(pdfItem => pdfItem.getDownloadURL())
      pdfUrls = await Promise.all(promises)

      commit('setCondoDocuments', pdfUrls)
    } catch (error) {
      console.error('Error getting PDFs:', error)
      commit('setError', 'Unable to process request. ' + error.message)
    } finally {
      commit('setLoading', false)
    }
  },

  async loadUserPoliciesStillwaterCondo ({ commit }) {
    commit('setCondoPolicies', [])
    try {
      const policies = []
      const userKey = firebase.auth().currentUser.uid
      const snapshot = await firebase
        .database()
        .ref('users')
        .child(userKey)
        .child('policies')
        .child('stillwater')
        .once('value')
      snapshot.forEach((childSnapshot) => {
        const policy = childSnapshot.val()
        if (policy.policyTypeCd === '06') {
          policies.push({
            ...policy,
            key: childSnapshot.key,
          })
        }
      })
      console.log('Stillwater condo policies:', policies)
      commit('setCondoPolicies', policies)
    } catch (error) {
      console.error(error)
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}

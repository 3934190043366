export default {
  en: {
    messages: {
      required: 'This field is required',
      email: 'Please enter a valid email',
      requiredOption: 'Please select an option',
      regex: 'Field format is invalid',
      credit_card: 'Please enter a valid credit card number',
      numeric: 'Value must be numeric.'
      // ... other rules and their messages
    },
  },
  fr: {
    messages: {
      required: 'Ce champ est obligatoire.',
      email: 'Veuillez saisir une adresse courriel valide.',
      requiredOption: 'Veuillez sélectionner une option.',
      regex: 'Le format du champ est invalide.',
      credit_card: 'Veuillez entrer un numéro de carte de crédit valide',
      numeric: 'Valeur numéro être numérique.'

      // ... other rules and their messages
    },
  },
}

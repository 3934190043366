import { firebase } from '@firebase/app'
import '@firebase/database'
import '@firebase/storage'
import 'firebase/functions'
import 'firebase/auth'
import moment from 'moment'

const state = {
  afficiencyQuestions: [],
  USTermQuote: '',
  arcId: '',
  afficiencyBeneficiaries: '',
  requirePII: false,
  lifeApplicant: {},
  mandatoryInfo: [],
  afficiencyConsentPdfs: [],
  UStermPdfs: [],
  notApprovedMsg: '',
  approvedMsg: '',
  afficiencyDecision: '',
  afficiencyNextStep: '',
  USTermDecisionLoading: false,
  USTermPremium: '',
  afficiencyStatusMessage: '',
  USTermExistingApplication: false,
  USTermStatusLoading: false,
  afficiencyApplicationData: '',
  afficiencySavedQuote: '',
  USTermPolicyNumber: '',
  USTermSessionId: '',
  USTermClientNumber: '',
  PIIError: '',
  afficiencyAppData: '',
  USTermPolicies: '',
  afficiencyPolicyNumLoading: false,
  USTermCompletedApplication: '',
  afficiencyInProgress: false,
  afficiencyPaymentError: '',
}

const getters = {
  getAfficiencyQuestions (state) {
    return state.afficiencyQuestions
  },
  getArcId (state) {
    return state.arcId
  },
  getUSTermQuote (state) {
    return state.USTermQuote
  },
  getAfficiencyBeneficiaries (state) {
    return state.afficiencyBeneficiaries
  },
  getRequirePII (state) {
    return state.requirePII
  },
  getLifeApplicant (state) {
    return state.lifeApplicant
  },
  getLifeApplicantState (state) {
    return state.lifeApplicant.state
  },
  getManadatoryInfo (state) {
    return state.mandatoryInfo
  },
  getAfficiencyConsentPdfs (state) {
    return state.afficiencyConsentPdfs
  },
  getUSTermPdfs (state) {
    return state.UStermPdfs
  },
  getNotApprovedMsg (state) {
    return state.notApprovedMsg
  },
  getApprovedMsg (state) {
    return state.approvedMsg
  },
  getAfficiencyDecision (state) {
    return state.afficiencyDecision
  },
  getAfficiencyNextStep (state) {
    return state.afficiencyNextStep
  },
  getUSTermDecisionLoading (state) {
    return state.USTermDecisionLoading
  },
  getUSTermPremium (state) {
    return state.USTermPremium
  },
  getAfficiencyStatusMessage (state) {
    return state.afficiencyStatusMessage
  },
  getUSTermExistingApplication (state) {
    return state.USTermExistingApplication
  },
  getUSTermStatusLoading (state) {
    return state.USTermStatusLoading
  },
  getAfficiencyApplicationData (state) {
    return state.afficiencyAppData
  },
  getAfficiencySavedQuote (state) {
    return state.afficiencySavedQuote
  },
  getUSTermPolicyNumber (state) {
    return state.USTermPolicyNumber
  },
  getUSTermSessionId (state) {
    return state.USTermSessionId
  },
  getUSTermClientNumber (state) {
    return state.USTermClientNumber
  },
  getPIIError (state) {
    return state.PIIError
  },
  getAfficiencyAppData (state) {
    return state.afficiencyAppData
  },
  getUSTermPolicies (state) {
    return state.USTermPolicies
  },
  getAfficiencyPolicyNumLoading (state) {
    return state.afficiencyPolicyNumLoading
  },
  getUSTermCompletedApplication (state) {
    return state.USTermCompletedApplication
  },
  getAfficiencyInProgress (state) {
    return state.afficiencyInProgress
  },
  getAfficiencyPaymentError (state) {
    return state.afficiencyPaymentError
  },
}

const mutations = {
  resetAfficiencyData (state) {
    console.log('resetAfficiencyData')
    state.afficiencyQuestions = []
    state.USTermQuote = ''
    state.arcId = ''
    state.afficiencyBeneficiaries = ''
    state.requirePII = false
    state.lifeApplicant = {}
    state.mandatoryInfo = []
    state.afficiencyConsentPdfs = []
    state.UStermPdfs = []
    state.notApprovedMsg = ''
    state.approvedMsg = ''
    state.afficiencyDecision = ''
    state.afficiencyNextStep = ''
    state.USTermDecisionLoading = false
    state.USTermPremium = ''
    state.afficiencyStatusMessage = ''
    state.USTermExistingApplication = false
    state.USTermStatusLoading = false
    state.afficiencyApplicationData = ''
    state.afficiencySavedQuote = ''
    state.USTermPolicyNumber = ''
    state.USTermSessionId = ''
    state.USTermClientNumber = ''
    state.PIIError = ''
    state.afficiencyAppData = ''
    state.USTermPolicies = ''
    state.afficiencyPolicyNumLoading = false
    state.USTermCompletedApplication = ''
    state.afficiencyInProgress = false
    state.afficiencyPaymentError = ''
    console.log('policy Number', state.USTermPolicyNumber)
  },
  setUSTermPdfs (state, payload) {
    state.UStermPdfs = payload
  },
  setUSTermQuote (state, payload) {
    state.USTermQuote = payload
  },
  setArcId (state, payload) {
    state.arcId = payload
  },
  setAfficiencyQuestions (state, payload) {
    state.afficiencyQuestions = payload
  },
  setAfficiencyBeneficiaries (state, payload) {
    state.afficiencyBeneficiaries = payload
  },
  setRequirePII (state, payload) {
    state.requirePII = payload
  },
  setLifeApplicant (state, payload) {
    state.lifeApplicant = payload
  },
  setMandatoryInfo (state, payload) {
    state.mandatoryInfo = payload
  },
  setAfficiencyConsentPdfs (state, payload) {
    state.afficiencyConsentPdfs = payload
  },
  setNotApprovedMsg (state, payload) {
    state.notApprovedMsg = payload
  },
  clearNotApprovedMsg (state) {
    state.notApprovedMsg = ''
  },
  setApprovedMsg (state, payload) {
    state.approvedMsg = payload
  },
  clearApprovedMsg (state) {
    state.approvedMsg = ''
  },
  setAfficiencyDecision (state, payload) {
    state.afficiencyDecision = payload
  },
  clearAfficiencyDecision (state) {
    state.afficiencyDecision = {}
  },
  setAfficiencyNextStep (state, payload) {
    state.afficiencyNextStep = payload
  },
  setUSTermDecisionLoading (state, payload) {
    state.USTermDecisionLoading = payload
  },
  setUSTermPremium (state, payload) {
    state.USTermPremium = payload
  },
  setAfficiencyStatusMessage (state, payload) {
    state.afficiencyStatusMessage = payload
  },
  setUSTermExistingApplication (state, payload) {
    state.USTermExistingApplication = payload
  },
  setLoading (state, payload) {
    state.USTermStatusLoading = payload
  },
  setAfficiencyApplicationData (state, payload) {
    state.afficiencyApplicationData = payload
  },
  setAfficiencySavedQuote (state, payload) {
    state.afficiencySavedQuote = payload
  },
  setUSTermPolicyNumber (state, payload) {
    state.USTermPolicyNumber = payload
  },
  setUSTermSessionId (state, payload) {
    state.USTermSessionId = payload
  },
  setUSTermClientNumber (state, payload) {
    state.USTermClientNumber = payload
  },
  setPIIError (state, payload) {
    state.PIIError = payload
  },
  setAfficiencyAppData (state, payload) {
    state.afficiencyAppData = payload
  },
  setUSTermPolicies (state, payload) {
    state.USTermPolicies = payload
  },
  setAfficiencyPolicyNumLoading (state, payload) {
    state.afficiencyPolicyNumLoading = payload
  },
  setUSTermCompletedApplication (state, payload) {
    state.USTermCompletedApplication = payload
  },
  setAfficiencyApllicationStarted (state, payload) {
    state.afficiencyInProgress = payload
  },
  setAfficiencyPaymentError (state, payload) {
    state.afficiencyPaymentError = payload
  },
}
const actions = {
  setAfficiencyInProgress ({ commit }, payload) {
    commit('setAfficiencyApllicationStarted', payload)
  },
  resetAfficiencyData ({ commit }) {
    commit('resetAfficiencyData')
  },
  clearAfficiencyNextStep ({ commit }) {
    commit('setAfficiencyNextStep', '')
  },
  clearApprovedMsg ({ commit }) {
    commit('clearApprovedMsg')
  },
  clearNotApprovedMsg ({ commit }) {
    commit('clearNotApprovedMsg')
  },
  setAfficiencyBeneficiaries ({ commit }, payload) {
    commit('setAfficiencyBeneficiaries', payload)
  },
  setPIIError ({ commit }, payload) {
    commit('setPIIError', payload)
  },
  setAfficiencySavedQuote ({ commit }, payload) {
    commit('setAfficiencySavedQuote', payload)
  },
  setUSTermExistingApplication ({ commit }, payload) {
    commit('setUSTermExistingApplication', payload)
  },
  setAfficiencyPaymentError ({ commit }, payload) {
    commit('setAfficiencyPaymentError', payload)
  },
  // US Term life AFFICEINCY
  async getUSTermQuote ({ commit, getters, dispatch }, payload) {
    commit('setLoading', true)
    const userkey = getters.user?.id

    const rec = {
      gender: payload.gender,
      dob: payload.dob,
      term: payload.term,
      zip: payload.zip,
      state: payload.state,
      height: payload.height,
      weight: payload.weight,
      tobaccoUse: payload.tobaccoUse,
      coverage: payload.coverage,
    }

    if (payload.state === 'NY') {
      commit(
        'setError',
        'Unfortunately this product is not available to residents of New York.'
      )

      if (userkey) {
        dispatch('USTermSaveQuote', { resetData: true })
      }

      commit('setLoading', false)
      return
    }

    try {
      const getUSTermQuote = firebase.functions().httpsCallable('getUSTermQuote')
      console.log('getUSTermQuote REC ', rec)

      const { data } = await getUSTermQuote(rec)
      console.log('DATA', data)
      const response = data.response

      if (response.haserror === true || response[0]?.haserror === true) {
        const msg = response.data.msgText || response.data.msg || ''
        commit('setError', 'Unable to process request: ' + msg)

        if (userkey) {
          dispatch('USTermSaveQuote', { resetData: true })
        }

        commit('setLoading', false)
        return
      }

      console.log('setting quote response with', response.data)
      const resp = response.data
      commit('setLifeApplicant', payload)
      commit('setUSTermQuote', resp)
      commit('setArcId', resp.arcId)
      commit('setLoading', false)
    } catch (error) {
      commit('setError', 'Unable to process request')
      console.log(error)
      commit('setLoading', false)
    }
  },

  async USTermSaveQuote ({ commit, getters }, payload) {
    console.log('CALLING USTermSaveQuote', payload)
    commit('setLoading', true)
    let user = getters.user
    let userkey = user.id
    let rec
    payload.resetData
      ? (rec = {
          userkey: userkey,
        })
      : (rec = {
          userkey: userkey,
          arcId: getters.getArcId,
          term: payload.term,
          coverage: payload.coverage,
          premium: payload.premium,
          gender: payload.gender,
          dob: payload.dob,
          zip: payload.zip,
          state: payload.state,
          height: payload.height,
          weight: payload.weight,
          tobaccoUse: payload.tobaccoUse,
        })
    let USTermSaveQuote = firebase
      .functions()
      .httpsCallable('USTermSaveAppData')
    console.log('USTermSaveAppData REC ', rec)
    return USTermSaveQuote(rec)
      .then((data) => {
        console.log('SAVE QUOTE DATA', data)
        let resp = data.data.response.data
        if (
          (data.data.response.haserror === undefined &&
            data.data.response[0].haserror === true) ||
          (!(data.data.response.haserror === undefined) &&
            data.data.response.haserror === true)
        ) {
          console.log('ERROR', data.data.response.message[0])
          commit(
            'setError',
            'Unable to process request: ' + data.data.response.message[0]
          )
          commit('setLoading', false)
          return
        }
        console.log('SAVED QUOTE', resp)
        commit('setLoading', false)
      })
      .catch((error) => {
        commit('setError', 'Unable to process request')
        console.log(error)
        commit('setLoading', false)
      })
  },

  async getAfficiencyAppStatus ({ commit, getters, dispatch }) {
    commit('setLoading', true)
    let user = getters.user
    let userkey = user.id
    let rec = {
      userkey: userkey,
    }
    let getAfficiencyAppStatus = firebase
      .functions()
      .httpsCallable('USTermExistingAppStatus')
    console.log('USTermExistingAppStatus Rec', rec)
    return getAfficiencyAppStatus(rec)
      .then((data) => {
        console.log('APP STATUS DATA', data)
        let resp = data.data.response.data
        if (
          (data.data.response.haserror === undefined &&
            data.data.response[0].haserror === true) ||
          (!(data.data.response.haserror === undefined) &&
            data.data.response.haserror === true)
        ) {
          commit(
            'setError',
            'Unable to process request: ' + data.data.response.message
          )
          commit('setLoading', false)
          return
        }
        if (data.data.response.arcId !== undefined) {
          console.log('setting Arc Id with', data.data.response.arcId)
          commit('setArcId', data.data.response.arcId)
        }
        if (resp === 0) {
          // NEW APPLICATION
          dispatch('getAfficiencyAppData').then(() => {
            // check if necessary app data exists to get quote
            if (getters.getAfficiencyAppData.tobaccoUse !== undefined) {
              dispatch('getUSTermQuote', getters.getAfficiencyAppData).then(
                () => {
                  console.log('setting next step with', 'SAVEDQUOTE')
                  commit('setAfficiencyNextStep', 'SAVEDQUOTE')
                  commit('setUSTermExistingApplication', true)
                }
              )
            }
          })
        } else if (resp === 1) {
          console.log('CONTINUE APPLICATION')
          commit('setUSTermExistingApplication', true)
          dispatch('getAfficiencyAppData')
          let mandatoryInfo = data.data.response.mandatoryInfoNeed
          console.log('mandatoryInfo', mandatoryInfo)
          let nextStep
          if (mandatoryInfo.includes('PII')) {
            nextStep = 'PII'
          } else if (mandatoryInfo.includes('UWQ')) {
            nextStep = 'UWQ'
          } else if (mandatoryInfo.includes('BENE')) {
            nextStep = 'BENE'
          } else if (mandatoryInfo.includes('PAYMENT')) {
            nextStep = 'PAYMENT'
          } else if (mandatoryInfo === 'SUBMIT') nextStep = 'SUBMIT'
          console.log('setting next step with', nextStep)
          commit('setAfficiencyNextStep', nextStep)
        } else if (resp === 2) {
          console.log('COMPLETE APPLICATION')
          commit('setUSTermExistingApplication', true)
          dispatch('getAfficiencyAppData')
          commit('setUSTermCompletedApplication', true)
        }
        commit('setLoading', false)
      })
      .catch((error) => {
        commit('setError', 'Unable to process request')
        console.log(error)
        commit('setLoading', false)
      })
  },

  async getAfficiencyAppData ({ commit, getters, dispatch }, payload) {
    console.log('calling getAfficiencyAppData')
    commit('setLoading', true)
    let user = getters.user
    let userkey = user.id
    let rec = {
      userkey: userkey,
    }
    let getAfficiencyAppData = firebase
      .functions()
      .httpsCallable('USTermGetAppData')
    console.log('USTermGetAppData Rec', rec)
    return getAfficiencyAppData(rec)
      .then((data) => {
        console.log('USTermGetAppData', data)
        let resp = data.data.response.data
        if (
          (data.data.response.haserror === undefined &&
            data.data.response[0].haserror === true) ||
          (!(data.data.response.haserror === undefined) &&
            data.data.response.haserror === true)
        ) {
          commit(
            'setError',
            'Unable to process request: ' + data.data.response.message
          )
          commit('setLoading', false)
          return
        }
        commit('setAfficiencySavedQuote', resp)
        commit('setAfficiencyAppData', resp)
        commit('setLoading', false)
      })
      .catch((error) => {
        commit('setError', 'Unable to process request')
        console.log(error)
        commit('setLoading', false)
      })
  },

  async USTermGetPolicyNumber ({ commit, getters }, payload) {
    console.log('CALLING USTermGetPolicyNumber')
    commit('setAfficiencyPolicyNumLoading', true)
    let user = getters.user
    let userkey = user.id
    let arcId = getters.getArcId
    let rec = {
      userkey: userkey,
      arcId: arcId,
    }
    let getUSTermPolicyNumber = firebase
      .functions()
      .httpsCallable('USTermGetPolicyNumber', { timeout: 120000 })
    console.log('USTermGetPolicyNumber ', rec)
    return getUSTermPolicyNumber(rec)
      .then((data) => {
        console.log('Policy Number Data', data)
        let resp = data.data.response.data
        if (
          (data.data.response.haserror === undefined &&
            data.data.response[0].haserror === true) ||
          (!(data.data.response.haserror === undefined) &&
            data.data.response.haserror === true)
        ) {
          commit(
            'setError',
            'Unable to process request: ' + data.data.response.message
          )
          commit('setAfficiencyPolicyNumLoading', false)
          return
        }
        commit('setUSTermPolicyNumber', resp.policyNumber)
        commit('setUSTermSessionId', resp.sessionId)
        commit('setUSTermClientNumber', resp.clientNumber)
        commit('setAfficiencyPolicyNumLoading', false)
      })
      .catch((error) => {
        commit('setError', 'Unable to process request')
        console.log(error)
        commit('setAfficiencyPolicyNumLoading', false)
      })
  },

  async USTermInitApplication ({ commit, getters, dispatch }, payload) {
    console.log('calling USTermInitApplication', payload)
    console.log(getters.getArcId)
    commit('setLoading', true)
    let user = getters.user
    let userkey = user.id
    let arcId = getters.getArcId
    let savedUser = payload.user
    let savedQuote = ''
    if (payload.savedQuote) {
      savedQuote = payload.savedQuote
    }
    let rec = {}
    payload.savedQuote
      ? (rec = {
          userkey: userkey,
          arcId: getters.getArcId,
          coverage: savedUser.coverage,
          term: savedUser.term,
          firstName: savedUser.firstName,
          middleName: savedUser.middleName,
          lastName: savedUser.lastName,
          dob: savedUser.dob,
          gender: savedUser.gender,
          addressLine1: savedUser.addressLine1,
          addressLine2: '',
          city: savedUser.city,
          country: 'US',
          state: savedUser.state,
          zip: savedUser.zipCode,
          ssn: savedUser.ssn,
          email: savedUser.email,
          phone: savedUser.phone,
        })
      : payload.existingApplication
      ? (rec = {
          userkey: userkey,
          arcId: getters.getArcId,
          coverage: payload.existingApplication.coverage,
          term: payload.existingApplication.term,
          firstName: savedUser.firstName,
          middleName: savedUser.middleName,
          lastName: savedUser.lastName,
          dob: payload.existingApplication.personalDetails.dob,
          gender: payload.existingApplication.personalDetails.gender,
          addressLine1: savedUser.addressLine1,
          addressLine2: '',
          city: savedUser.city,
          country: 'US',
          state: savedUser.state,
          zip: savedUser.zipCode,
          ssn: savedUser.ssn,
          email: savedUser.email,
          phone: savedUser.phone,
        })
      : (rec = {
          userkey: userkey,
          arcId: arcId,
          coverage: savedUser.coverage,
          term: savedUser.term,
          firstName: savedUser.firstName,
          middleName: savedUser.middleName,
          lastName: savedUser.lastName,
          dob: savedUser.dob,
          gender: savedUser.gender,
          addressLine1: savedUser.addressLine1,
          addressLine2: '',
          city: savedUser.city,
          country: 'US',
          state: savedUser.state,
          zip: savedUser.zipCode,
          ssn: savedUser.ssn,
          email: savedUser.email,
          phone: savedUser.phone,
        })
    console.log('INIT APP REC', rec)
    let USTermInitApplication = firebase
      .functions()
      .httpsCallable('USTermInitApplication')
    return USTermInitApplication(rec)
      .then((data) => {
        console.log('INIT APP DATA', data)
        let resp = data.data.response.data
        if (
          (data.data.response.haserror === undefined &&
            data.data.response[0].haserror === true) ||
          (!(data.data.response.haserror === undefined) &&
            data.data.response.haserror === true) ||
          data.data.response.data.msgText
        ) {
          commit('setPIIError', data.data.response.data.msgText)
          commit('setAfficiencyNextStep', 'PII')
          commit('setLoading', false)
          return
        }

        console.log('response from US Term Init APP api server ', resp)
        let mandatoryInfo = resp.mandatoryInfoNeed
        let nextStep = ''
        if (mandatoryInfo.includes('PII')) {
          nextStep = 'PII'
        } else if (
          !mandatoryInfo.includes('PII') &&
          mandatoryInfo.includes('UWQ')
        ) {
          nextStep = 'UWQ'
        } else if (
          !mandatoryInfo.includes('UWQ') &&
          mandatoryInfo.includes('BENE')
        ) {
          nextStep = 'BENE'
        } else if (
          !mandatoryInfo.includes('BENE') &&
          mandatoryInfo.includes('PAYMENT')
        ) {
          nextStep = 'PAYMENT'
          dispatch('loadUSTermDocuments')
        } else if (mandatoryInfo === 'SUBMIT') nextStep = 'SUBMIT'

        // check application status
        commit('setAfficiencyNextStep', nextStep)
        commit('setLoading', false)

        // set loading false
      })
      .catch((error) => {
        commit('setError', 'Unable to process request')
        console.log(error)
        commit('setLoading', false)
      })
  },

  async USTermUpdatePII ({ commit, getters }, payload) {
    console.log('calling USTermUpdatePII')
    commit('setLoading', true)
    let rec = {
      arcId: getters.getArcId,
      firstName: 'AIMEE',
      middleName: '',
      lastName: 'BELMONTEZ',
      dob: '08/30/1988',
      gender: 'F',
      addressLine1: '317 West 14th Street',
      addressLine2: '',
      city: 'HAYS',
      country: 'US',
      state: 'CT',
      zip: '67601',
      ssn: '666329832',
      email: 'test@test.com',
      phone: '3366720704',
    }
    console.log('PII rec ', rec)
    let USTermUpdatePII = firebase.functions().httpsCallable('USTermUpdatePII')
    return USTermUpdatePII(rec)
      .then((data) => {
        console.log('USTermUpdatePII resp ', data.data.response)
        let quoteresponse = data.data.response
        // some business logic error from their api but your params passed validation
        if (
          (data.data.response.haserror === undefined &&
            data.data.response[0].haserror === true) ||
          (!(data.data.response.haserror === undefined) &&
            data.data.response.haserror === true)
        ) {
          commit(
            'setError',
            'Unable to process request: ' + data.data.response.data.msgText
          )
          commit('setLoading', false)
          return
        }
        console.log(
          'response from US Term Update PII api server ',
          quoteresponse
        )
        commit('setLoading', false)

        // set loading false
      })
      .catch((error) => {
        commit('setError', 'Unable to process request')
        console.log(error)
        commit('setLoading', false)
      })
  },
  async requestUSTermApplicationQuestions ({ commit, getters }, payload) {
    commit('setLoading', true)
    let rec = {
      arcId: getters.getArcId,
      state: payload.state,
    }
    let USTermApplicationQuestions = firebase
      .functions()
      .httpsCallable('USTermApplicationQuestions')
    console.log('requestUSTermApplicationQuestions ', rec)
    return USTermApplicationQuestions(rec)
      .then((data) => {
        console.log(
          'response from requestUSTermApplicationQuestions ',
          data.data.response.data
        )
        let questionresponse = data.data.response.data
        // some business logic error from their api but your params passed validation
        if (
          (data.data.response.haserror === undefined &&
            data.data.response[0].haserror === true) ||
          (!(data.data.response.haserror === undefined) &&
            data.data.response.haserror === true)
        ) {
          commit(
            'setError',
            'Unable to process request: ' + data.data.response.data.msgText
          )
          commit('setLoading', false)
          return
        }
        let appQJson = JSON.parse(questionresponse.appQJson)
        console.log('our quetsions ', appQJson)
        // just how they send it back to us, escape it twice. no idea why
        let AfficienySurveyQuetsions = JSON.parse(appQJson[0].uwqs)
        // sort by question ID
        AfficienySurveyQuetsions = AfficienySurveyQuetsions.sort(
          (a, b) => a.question_sequence_number - b.question_sequence_number
        )
        commit('setAfficiencyQuestions', AfficienySurveyQuetsions)
        console.log('our quetsions ', getters.getAfficiencyQuestions)
        commit('setLoading', false)

        // set loading false
      })
      .catch((error) => {
        commit('setError', 'Unable to process request')
        console.log(error)
        commit('setLoading', false)
      })
  },

  async submitUSTermApplicationAnswers (
    { commit, getters, dispatch, state },
    payload
  ) {
    commit('setLoading', true)
    let rec = {
      arcId: getters.getArcId,
      appQA: payload.appQA,
    }
    console.log('submitUSTermApplicationAnswers ', rec)
    let USTermApplicationAnswers = firebase
      .functions()
      .httpsCallable('USTermApplicationAnswers')
    return USTermApplicationAnswers(rec)
      .then((data) => {
        console.log(
          'response from submitUSTermApplicationAnswers ',
          data.data.response.data
        )
        let resp = data.data.response.data
        // some business logic error from their api but your params passed validation
        if (
          (data.data.response.haserror === undefined &&
            data.data.response[0].haserror === true) ||
          (!(data.data.response.haserror === undefined) &&
            data.data.response.haserror === true)
        ) {
          commit(
            'setError',
            'Unable to process request: ' + data.data.response.data.msgText
          )
          commit('setLoading', false)
          return
        }
        console.log('response from submitUSTermApplicationAnswers ', resp)
        if (resp.mandatoryInfoNeed.includes('BENE')) {
          commit('setAfficiencyNextStep', 'BENE')
        } else {
          commit('setAfficiencyNextStep', 'PAYMENT')
        }
        commit('setLoading', false)

        // set loading false
      })
      .catch((error) => {
        commit('setError', 'Unable to process request')
        console.log(error)
        commit('setLoading', false)
      })
  },
  async getUSTermDecision ({ commit, getters, dispatch }, payload) {
    let user = payload.user
    commit('setUSTermDecisionLoading', true)
    let rec = {
      arcId: getters.getArcId,
    }
    let getUSTermDecision = firebase
      .functions()
      .httpsCallable('USTermgetDecision')
    return getUSTermDecision(rec)
      .then((data) => {
        let resp = data.data.response.data
        console.log('Us Term get decision resp ', resp)
        if (
          (data.data.response.haserror === undefined &&
            data.data.response[0].haserror === true) ||
          (!(data.data.response.haserror === undefined) &&
            data.data.response.haserror === true)
        ) {
          commit(
            'setError',
            'Unable to process request: ' + data.data.response.data.msgText
          )
          commit('setUSTermDecisionLoading', false)
          return
        }
        let declinedCodes = [2, 3, 4, 6, 7, 10]
        if (resp.appDecisonCode === 99) {
          console.log('wait', resp.waitSeconds)
          let timeout = resp.waitSeconds * 1000
          console.log('Not ready for decision, calling again')
          return new Promise(function (resolve, reject) {
            setTimeout(() => {
              console.log('15 seconds Timer expired!!!')
              resolve()
            }, timeout)
          }).then(() => {
            dispatch('getUSTermDecision', { user: user })
          })
        }
        if (resp.appDecisonCode === 1) {
          let coverage = resp.maxAllowedCoverageByTerm
          if (coverage !== null) {
            coverage = coverage.replace(/'/g, '"') // regex magic
            coverage = JSON.parse(coverage)
            console.log(coverage)
          }
          let decisionRec = {
            appCompletePercentage: resp.appCompletePercentage,
            appDecisonCode: resp.appDecisonCode,
            appStatus: resp.appStatus,
            arcId: resp.arcId,
            coverageApproved: resp.coverageApproved,
            mandatoryInfoNeed: resp.mandatoryInfoNeed,
            maxAllowedCoverageByTerm: coverage,
            msgCode: resp.msgCode,
            msgTxt: resp.msgTxt,
          }
          commit(
            'setApprovedMsg',
            'Congratulations! You have been approved for coverage.'
          )
          console.log('Calling get premium', user)
          dispatch('USTermGetPremium', { user: user })
          dispatch('USTermGetPolicyNumber')
          commit('setAfficiencyDecision', decisionRec)
          console.log('decisionRec ', decisionRec)
        } else if (declinedCodes.includes(resp.appDecisonCode)) {
          console.log('not approved')
          dispatch('USTermGetForms', { stage: 3 }).then(() => {
            commit('setNotApprovedMsg', resp.msgTxt)
            commit('setUSTermDecisionLoading', false)
          })
          return
        }
        commit('setUSTermDecisionLoading', false)
      })
      .catch((error) => {
        console.log(error)
        commit('setError', 'Unable to process request')
        commit('setUSTermDecisionLoading', false)
      })
  },
  // submitbene
  // auth for hippa, background check
  async USTermSubmitAuth ({ commit, getters, dispatch }, payload) {
    commit('setLoading', true)
    let rec = {
      arcId: getters.getArcId,
    }
    let USTermSubmitAuth = firebase
      .functions()
      .httpsCallable('USTermSubmitAuth')
    return USTermSubmitAuth(rec)
      .then((data) => {
        console.log(
          'response from US Term Submit Auth server ',
          data.data.response
        )
        let resp = data.data.response
        if (
          (data.data.response.haserror === undefined &&
            data.data.response[0].haserror === true) ||
          (!(data.data.response.haserror === undefined) &&
            data.data.response.haserror === true)
        ) {
          commit(
            'setError',
            'Unable to process request: ' + data.data.response.data.msgText
          )
          commit('setLoading', false)
          return
        }
        console.log('Submit Auth resp', resp)
        commit('setLoading', false)

        // set loading false
      })
      .catch((error) => {
        console.log(error)
        commit('setError', 'Unable to process request')
        commit('setLoading', false)
      })
  },
  async USTermSubmitBene ({ commit, getters }, payload) {
    commit('setLoading', true)
    let user = getters.user
    let userkey = user.id
    let rec = {
      userkey: userkey,
      arcId: getters.getArcId,
      beneficiaryInfo: payload.beneficiaries,
      designeeFlag: payload.designee ? 1 : 0,
    }
    if (payload.designee) {
      Object.assign(rec, { designee: payload.designee })
    }
    let USTermSubmitBene = firebase
      .functions()
      .httpsCallable('USTermSubmitBene')
    return USTermSubmitBene(rec)
      .then((data) => {
        console.log('Beneficiary rec', rec)
        console.log(data)
        if (
          (data.data.response.haserror === undefined &&
            data.data.response[0].haserror === true) ||
          (!(data.data.response.haserror === undefined) &&
            data.data.response.haserror === true)
        ) {
          commit(
            'setError',
            'Unable to process request: ' + data.data.response.data.msgText
          )
          commit('setLoading', false)
          return
        }
        commit('setLoading', false)

        // set loading false
      })
      .catch((error) => {
        console.log(error)
        commit('setError', 'Unable to process request')
        commit('setLoading', false)
      })
  },
  async USTermGetPremium ({ commit, getters }, payload) {
    let user = payload.user
    let rec = {
      arcId: getters.getArcId,
      coverage: user.coverage,
      term: user.term,
    }
    console.log('USER', user, 'REC', rec)
    let USTermGetPremium = firebase
      .functions()
      .httpsCallable('USTermGetPremium')
    return USTermGetPremium(rec)
      .then((data) => {
        console.log(
          'response from US Get Prem api server ',
          data.data.response
        )
        let resp = data.data.response
        // some business logic error from their api but your params passed validation
        if (
          (data.data.response.haserror === undefined &&
            data.data.response[0].haserror === true) ||
          (!(data.data.response.haserror === undefined) &&
            data.data.response.haserror === true)
        ) {
          commit(
            'setError',
            'Unable to process request: ' + data.data.response.data.msgText
          )
          return
        }
        console.log('response ', resp)
        commit('setUSTermPremium', resp.data)
      })
      .catch((error) => {
        console.log(error)
        commit('setError', 'Unable to process request')
      })
  },

  async USTermOfferAcceptance ({ commit, getters }, payload) {
    commit('setLoading', true)
    let currentUser = getters.user
    let userkey = currentUser.id
    let rec = {
      userkey: userkey,
      arcId: getters.getArcId,
      coverage: payload.coverage,
      term: payload.term,
      premium: payload.premium,
    }
    console.log('Calling USTermOfferAcceptance with rec', rec)
    let USTermOfferAcceptance = firebase
      .functions()
      .httpsCallable('USTermOfferAcceptance')
    return USTermOfferAcceptance(rec)
      .then((data) => {
        console.log(
          'response from US Term Offer Acceptance ',
          data.data.response
        )
        let resp = data.data.response
        // some business logic error from their api but your params passed validation
        if (
          (data.data.response.haserror === undefined &&
            data.data.response[0].haserror === true) ||
          (!(data.data.response.haserror === undefined) &&
            data.data.response.haserror === true)
        ) {
          commit(
            'setError',
            'Unable to process request: ' + data.data.response.data.msgText
          )
          commit('setLoading', false)
          return
        } else {
          if (resp.statusCode === 200) {
            console.log('they have acknowledged our acceptance of the offer')
          }
          console.log('response ', resp.statusCode)
        }
        commit('setLoading', false)

        // set loading false
      })
      .catch((error) => {
        console.log(error)
        commit('setError', 'Unable to process request')
        commit('setLoading', false)
      })
  },

  async USTermPaymentCC ({ commit, getters }, payload) {
    commit('setLoading', true)
    console.log('PAYLOAD', payload)
    let paymentType = payload.paymentType
    let payAbbr = ''
    if (paymentType === 'Visa') {
      payAbbr = 'VI'
    } else if (paymentType.includes('Master')) {
      payAbbr = 'MC'
    }

    let paymentFrequency = payload.paymentFrequency
    let frequency
    if (paymentFrequency === 'Monthly') {
      frequency = 1
    } else if (paymentFrequency === 'Quarterly') {
      frequency = 2
    } else if (paymentFrequency === 'Semi-Annually') {
      frequency = 3
    } else if (paymentFrequency === 'Annually') {
      frequency = 4
    }

    let paymentPremium = Number(payload.paymentPremium)
    let tokenId = payload.tokenId
    let timestamp = moment().format('YYYY-MM-DD HH:mm:ss')
    let user = getters.user
    let userkey = user.id
    let rec = {
      userkey: userkey,
      arcId: getters.getArcId,
      payType: 2,
      payMethod: 3,
      paymentType: payAbbr,
      payStatus: 1,
      paymentFrequency: frequency,
      paymentPremium: paymentPremium,
      tokenId: tokenId,
      payConsent: 1,
      payConsentTimeStamp: timestamp,
      cardExpirationMonth: payload.cardExpirationMonth,
      cardExpirationYear: payload.cardExpirationYear,
      lastFourDigits: payload.lastFourDigits,
    }
    console.log('Calling US Term Payment CC', rec)
    let USTermPaymentCC = firebase.functions().httpsCallable('USTermPaymentCC')
    return USTermPaymentCC(rec)
      .then((data) => {
        console.log(
          'response from US Term Payment CC api server ',
          data.data.response
        )
        let resp = data.data.response
        // some business logic error from their api but your params passed validation
        if (
          (data.data.response.haserror === undefined &&
            data.data.response[0].haserror === true) ||
          (!(data.data.response.haserror === undefined) &&
            data.data.response.haserror === true)
        ) {
          commit(
            'setError',
            'Unable to process request: ' + data.data.response.data.msgText
          )
          commit('setLoading', false)
          return
        } else {
          console.log('payment complete')
          commit('setAfficiencyNextStep', 'SUBMIT')
          console.log('response ', resp.statusCode)
        }
        commit('setLoading', false)

        // set loading false
      })
      .catch((error) => {
        console.log(error)
        commit('setError', 'Unable to process request')
        commit('setLoading', false)
      })
  },
  async USTermPaymentACH ({ commit, getters }, payload) {
    commit('setLoading', true)
    let arcId = getters.getArcId
    let timestamp = moment().format('YYYY-MM-DD HH:mm:ss')
    let paymentFrequency = payload.paymentFrequency
    let frequency
    let paymentPremium = parseFloat(payload.paymentPremium)
    if (paymentFrequency === 'Monthly') {
      frequency = 1
    } else if (paymentFrequency === 'Quarterly') {
      frequency = 2
    } else if (paymentFrequency === 'Semi-Annually') {
      frequency = 3
    } else if (paymentFrequency === 'Annually') {
      frequency = 4
    }
    let user = getters.user
    let userkey = user.id
    let rec = {
      userkey: userkey,
      arcId: arcId,
      payType: 2,
      payMethod: 2,
      bankAccountNo: payload.bankAccountNo,
      bankAccountType: payload.bankAccountType,
      bankRoutingNo: payload.bankRoutingNo,
      bankName: payload.bankName,
      payStatus: 1,
      paymentFrequency: frequency,
      paymentPremium: paymentPremium,
      payConsent: 1,
      payConsentTimeStamp: timestamp,
    }
    console.log('Calling US Term Payment ACH', rec)
    let USTermPaymentACH = firebase
      .functions()
      .httpsCallable('USTermPaymentACH')
    return USTermPaymentACH(rec)
      .then((data) => {
        console.log(
          'response from USTermPaymentACH api server ',
          data.data.response
        )
        let resp = data.data.response
        // some business logic error from their api but your params passed validation
        if (
          (data.data.response.haserror === undefined &&
            data.data.response[0].haserror === true) ||
          (!(data.data.response.haserror === undefined) &&
            data.data.response.haserror === true)
        ) {
          commit(
            'setAfficiencyPaymentError',
            'Unable to process request: ' + data.data.response.data.msgText
          )
          commit('setLoading', false)
          return
        } else {
          commit('setAfficiencyNextStep', 'SUBMIT')
          console.log('response ', resp.statusCode)
        }
        commit('setLoading', false)
      })
      .catch((error) => {
        console.log(error)
        commit('setError', 'Unable to process request')
        commit('setLoading', false)
      })
  },
  async loadUSTermDocuments ({ commit, getters }, payload) {
    const arcId = getters.getArcId
    commit('setUSTermPdfs', []) // reset
    commit('setnoticesloading', true)
    const storageRef = firebase
      .storage()
      .ref()
      .child('policies/afficiency/' + arcId)
    try {
      const { items } = await storageRef.listAll()
      const pdfUrls = await Promise.all(
        items.map(async (pdfItem) => {
          const url = await pdfItem.getDownloadURL()
          const fileName = pdfItem.name
          return { url, fileName }
        })
      )
      commit('setUSTermPdfs', pdfUrls)
      console.log('pdfURLS', pdfUrls)
      commit('setnoticesloading', false)
    } catch (error) {
      console.log('error getting pdfs', error)
      commit('setError', 'Unable to process request')
      commit('setnoticesloading', false)
    }
  },
  async USTermGetForms ({ commit, getters, dispatch }, payload) {
    commit('setLoading', true)
    let arcId = getters.getArcId
    let rec = {
      arcId: arcId,
      appStage: payload.stage,
    }
    let USTermGetForms = firebase.functions().httpsCallable('USTermGetForms')
    return USTermGetForms(rec)
      .then((data) => {
        console.log('response from US Term Forms ', data)
        let resp = data.data.response
        // some business logic error from their api but your params passed validation
        if (
          (data.data.response.haserror === undefined &&
            data.data.response[0].haserror === true) ||
          (!(data.data.response.haserror === undefined) &&
            data.data.response.haserror === true)
        ) {
          commit(
            'setError',
            'Unable to process request: ' + data.data.response.data.msgText
          )
          commit('setLoading', false)
          return
        }
        console.log('they have forms for us')
        console.log('response ', resp)
        dispatch('loadUSTermDocuments')
        commit('setLoading', false)

        // set loading false
      })
      .catch((error) => {
        console.log(error)
        commit('setError', 'Unable to process request')
        commit('setLoading', false)
      })
  },
  async USTermAppSign ({ commit, getters }) {
    try {
      commit('setLoading', true)
      const userkey = getters.user.id
      const pdfs = getters.getUSTermPdfs
      const appSignConsentJson = pdfs.map((pdf) => ({
        esign_timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
        form_no: parseInt(pdf.fileName),
        doc_full_view_flag: 1,
        esign_flag: 1,
      }))
      const rec = {
        arcId: getters.getArcId,
        userkey,
        appSignConsentJson,
        callReferenceCode: 207,
      }
      console.log('Calling US Term App Sign ', rec)
      const USTermAppSign = firebase.functions().httpsCallable('USTermAppSign')
      const { data } = await USTermAppSign(rec)
      console.log('response from US Term App Sign ', data.response)

      const response = data.response
      // handle business logic errors from the API
      if (
        response.haserror ||
        (Array.isArray(response) && response[0].haserror)
      ) {
        const msgText = response.data?.msgText || 'Unable to process request'
        commit('setError', `Error: ${msgText}`)
      } else {
        console.log('They have acknowledged the signed documents')
        console.log('Response: ', response.statusCode)
        console.log('App Sign Response: ', response.data)
      }
    } catch (error) {
      console.log(error)
      commit('setError', 'Unable to process request')
    } finally {
      commit('setLoading', false)
    }
  },
  async USTermUpdateSession ({ commit, getters }) {
    commit('setLoading', true)
    let arcId = getters.getArcId
    let rec = {
      arcId: arcId,
      sessionStatus: 100,
    }
    let USTermUpdateSession = firebase
      .functions()
      .httpsCallable('USTermUpdateSession')
    return USTermUpdateSession(rec)
      .then((data) => {
        console.log('response from USTermUpdateSession api server ', data)
        let resp = data.data.response
        // some business logic error from their api but your params passed validation
        if (
          (data.data.response.haserror === undefined &&
            data.data.response[0].haserror === true) ||
          (!(data.data.response.haserror === undefined) &&
            data.data.response.haserror === true)
        ) {
          console.log('error from USTermUpdateSession')
        }
        console.log('USTermUpdateSession')
        console.log('response ', resp)
        commit('setLoading', false)

        // set loading false
      })
      .catch((error) => {
        console.log(error)
        commit('setLoading', false)
      })
  },

  loaduserpoliciesAfficiency ({ commit }) {
    console.log('loaduserpoliciesAfficiency')
    const policies = []
    let userkey = firebase.auth().currentUser.uid
    console.log('userkey ', userkey)
    firebase
      .database()
      .ref('users')
      .child(userkey)
      .child('policies')
      .child('afficiency')
      .once('value')
      .then((data) => {
        const obj = data.val()
        console.log('AFFICIENCY obj ', obj)
        console.log('usterm obj ', obj)
        policies.push({
          ClientNumber: obj.clientNumber,
          Coverage: obj.coverage,
          PolicyNumber: obj.policyNum,
          Premium: obj.paymentPremium,
          Term: obj.term,
          created: obj.created,
          personalDetails: obj.personalDetails,
        })

        console.log('afficiency policies ', policies)
        commit('setUSTermPolicies', policies)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  async signUserInAfficiency ({ commit, dispatch }, payload) {
    commit('clearError')
    commit('setLoading', true)
    return firebase
      .auth()
      .signInWithEmailAndPassword(payload.email, payload.password)
      .then((user) => {
        let key = firebase.auth().currentUser.uid
        // console.log('signed in ', firebase.auth().currentUser.uid)
        firebase
          .database()
          .ref('users')
          .child(key)
          .once('value')
          .then((data) => {
            const obj = data.val()
            let feet = ''
            let inches = ''
            let height = {
              feet: feet,
              inches: inches,
            }
            console.log('user obj ', obj)
            let myuser = {
              key: '',
              email: payload.email,
              height: {
                feet: 0,
                inches: 0,
              },
              weight: 0,
              group: 'UNSET',
              level: 0,
              status: 'NONE',
              ssn: '',
              dob: '',
              driverlicense: '',
              bio: 'nothing',
              alerts: [],
              policies: [],
              firstname: '',
              lastname: '',
              mobile: '',
              address: '',
              city: '',
              country: 'US',
              postcode: '',
            }
            myuser.key = firebase.auth().currentUser.uid
            myuser.id = firebase.auth().currentUser.uid
            myuser.policies = obj.policies
            myuser.height = obj.height
            myuser.weight = obj.weight
            myuser.dob = obj.dob
            myuser.ssn = obj.ssn
            myuser.email = obj.email
            myuser.level = obj.level
            myuser.status = obj.status
            myuser.username = obj.username
            myuser.firstname = obj.firstname
            myuser.lastname = obj.lastname
            myuser.mobile = obj.mobile
            myuser.address = obj.address
            myuser.city = obj.city
            myuser.country = obj.country
            myuser.postcode = obj.postcode
            myuser.bdays = obj.bdays
            myuser.bmonths = obj.bmonths
            myuser.byears = obj.byears
            myuser.heightinches = obj.heightinches
            myuser.heightfeet = obj.heightfeet
            myuser.weightpounds = obj.weightpounds
            myuser.weightkilos = obj.weightkilos
            // const newUser = {
            //   key: key,
            //   id: payload.uid,
            //   alerts: obj.alerts,
            //   policies: obj.policies,
            //   height: height,
            //   weight: obj.weight,
            //   dob: obj.dob,
            //   ssn: obj.ssn,
            //   email: obj.email,
            //   level: obj.level,
            //   status: obj.status,
            //   username: obj.username,
            //   firstname: obj.firstname,
            //   lastname: obj.lastname,
            //   mobile: obj.mobile,
            //   address: obj.address,
            //   city: obj.city,
            //   country: obj.country,
            //   postcode: obj.postcode,
            // }
            console.log('setting user data from signUserIn ', myuser)
            commit('setUser', myuser)
            commit('setcurrentuserkey', firebase.auth().currentUser.uid)
            dispatch('getAfficiencyAppStatus', { noArc: true })
            // router.push('/')
          })
          .catch((error) => {
            let result = JSON.parse(JSON.stringify(error))
            commit('setloginError', result.message)
            console.log('error ', JSON.parse(JSON.stringify(error)))
            commit('setError', result.message)
            commit('setLoading', false)
          })
      })
      .catch((error) => {
        let result = JSON.parse(JSON.stringify(error))
        commit('setloginError', result.message)
        console.log('error ', JSON.parse(JSON.stringify(error)))
        commit('setError', result.message)
        commit('setLoading', false)
      })
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}

<template>
  <div>
    <router-view />
    <v-dialog
      :key="retry"
      v-model="snackbar"
      theme="light"
      max-width="700"
      color="white"
      persistent
    >
      <v-card
        style="background: #fff"
        theme="light"
        min-height="20vh"
      >
        <v-card-text class="justify-center text-center">
          <v-row>
            <v-col
              data-testid="globalErrorMessage"
              style="text-transform: capitalize"
            >
              {{
                checkError()
              }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-row no-gutters>
          <v-col>
            <div class="d-flex justify-center">
              <v-btn
                color="primary"
                class="mb-4 mt-4 font-weight-bold"
                data-testid="globalErrorCloseBtn"
                @click="closeSnackbar"
              >
                {{ $t('close') }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog
      :key="retry"
      v-model="networkDialog"
      theme="light"
      persistent
      max-width="700"
    >
      <v-card
        theme="light"
        style="background: #fff"
        min-height="20vh"
      >
        <v-card-text class="justify-center text-center">
          <v-row>
            <v-col
              data-testid="globalNetworkErrorMessage"
              class="style-text"
            >
              {{ $t("globals.errors.connection") }}
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'App',
    data () {
      return {
        snackbar: false,
        retry: 0,
        isCommonError: false,
        errorsArray: [],
        networkDialog: false // Control the network dialog visibility
      }
    },
    computed: {
      ...mapState(['error', 'loginerror', 'purchaseerror', 'canadianTravelError', 'usaTravelErrors', 'networkError'])
    },

    watch: {
      $router (val) {
        console.log(val)
      },
      error (val) {
        if (val) {
          this.errorsArray[0] = val
          this.showSnackbar()
        }
      },
      loginerror (val) {
        if (val) {
          this.errorsArray[1] = val
          this.showSnackbar()
        }
      },
      purchaseerror (val) {
        if (val) {
          this.errorsArray[2] = val
          this.showSnackbar()
        }
      },
      canadianTravelError (val) {
        if (val) {
          this.errorsArray[3] = val
          this.showSnackbar()
        }
      },
      usaTravelErrors (val) {
        if (val) {
          this.errorsArray[4] = val
          this.showSnackbar()
        }
      },
      networkError (val) {
        this.retry += 1
        this.networkDialog = val
        this.$vuetify.theme.dark = false
      },
      $route (to) {
        document.title = to.meta.title
        let existingCanonical = document.querySelector('link[rel="canonical"]')
        if (existingCanonical) {
          existingCanonical.remove()
        }
        if (to.meta.canonical) {
          let link = document.createElement('link')
          link.setAttribute('rel', 'canonical')
          link.setAttribute('href', to.meta.canonical)
          document.head.appendChild(link)
        }
      }
    },
    mounted () {
      this.$watch('$route', this.updateMeta)
      this.updateMeta()
      // reset the error on page load
      this.$nextTick(() => {
        this.$store.commit('setError', '')
      })
    },
    methods: {
      checkError () {
        const allErrors = Array.from(new Set([this.loginerror, this.error, this.purchaseerror, this.usaTravelErrors, this.canadianTravelError]))
          ?.filter(item => item)?.map(item => item.toLowerCase())
        const messages = [
          'internal',
          'permission-denied',
          'a network error (such as timeout, interrupted connection or unreachable host) has occurred.',
          'invalid-argument',
          'not-found',
          'unknown',
          'deadline-exceeded',
          'unavailable',
          'resource-exhausted'
        ]
        if (messages.some(message => allErrors.includes(message))) {
          this.isCommonError = true
          return this.$t('globals.errors.generic')
        }
        this.isCommonError = false
        return allErrors.join('<br/>')
      },
      updateMeta () {
        const to = this.$route
        document.title = to.meta.title || 'Blanket.com'
        let existingCanonical = document.querySelector('link[rel="canonical"]')
        if (existingCanonical) {
          existingCanonical.remove()
        }
        if (to.meta.canonical) {
          let link = document.createElement('link')
          link.setAttribute('rel', 'canonical')
          link.setAttribute('href', to.meta.canonical)
          document.head.appendChild(link)
        }
      },
      showSnackbar () {
        this.snackbar = false // Reset first
        this.$nextTick(() => {
          this.$vuetify.theme.dark = false
          this.snackbar = true // Show dialog
        })
        this.retry += 1 // Increment retry to force reactivity
      },
      closeSnackbar () {
        this.snackbar = false
        this.errorsArray.fill(false) // Reset the errors array
        this.$store.commit('setError', false)
        this.$store.commit('setloginError', false)
        this.$store.commit('setPurchaseError', false)
        this.$store.commit('setTravelCanadaError', false)
      },
      closeNetworkDialog () {
        this.networkDialog = false
      }
    }

  }
</script>

<style>
/* Prevent horizontal scrolling for the entire body */
html, body {
  overflow-x: hidden;
}

.v-application .grey.lighten-3 {
  background-color: #E1F4FE !important;
  border-color: #E1F4FE !important;
}

.v-overlay.v-overlay--active.theme--dark {
  .v-overlay__scrim {
    opacity: 0.6 !important;
  }
}

/* Hide scrollbar for small screen sizes */
@media (max-width: 768px) {
  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Remove underline decoration from all link v-btn components */
a.v-btn {
  text-decoration: none;
}

.v-dialog .v-card .v-card__title, .v-dialog > .v-card > .v-card__text {
  color: #000 !important;
}
.style-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
</style>

'use strict'
/* eslint-disable */
const load = () =>
{
  if (window.chargeoverLoaded) {
    return;
  }
  const instance = process.env.VUE_APP_CHARGEOVERJS_INSTANCE
  const token = process.env.VUE_APP_CHARGEOVERJS_TOKEN
  window.chargeoverLoaded = true;
  !(function ()
  {
    var t = window.chargeoverr = window.chargeover = window.chargeoverr || []
    if (!t.init) {
      if (t.invoked) return void (window.console && console.error && console.error('ChargeOver snippet included twice.'))
      t.invoked = !0, t.load = function (cb)
      {
        var o = document.createElement('script')
        o.type = 'text/javascript', o.async = !0, o.crossorigin = 'anonymous', o.src = 'https://assets.chargeover.com/minify/?g=chargeover.js'

        o.onload = o.onreadystatechange = function ()
        {
          if (!this.readyState || this.readyState == 'complete') {
            cb()
          }
        }
        var i = document.getElementsByTagName('script')[0]
        i.parentNode.insertBefore(o, i)
      }
    }
  }())

  window.chargeover.load(() =>
  {
    window.ChargeOver.Core.setup({
      instance: instance,
      token: token
    })
  })
}

const validateCC = async (data) => {
  console.log('validateCreditCard', data);

  try {
    const result = await new Promise((resolve) => {
      const callback = (code, message, response) => {
        console.log(response);
        if (code == 200) {
          resolve({ isValid: true, response, message });
        } else {
          resolve({ isValid: false, response, message });
        }
      };

      ChargeOver.CreditCard.validate(data, callback);
    });

    return result;

  } catch (error) {
    console.log(error);
    return { isValid: false, response: error };
  }
}

const validateACH = async (data) => {

  try {
    const result = await new Promise((resolve) => {
      const callback = (code, message, response) => {
        if (code == 200) {
          resolve({ isValid: true, response, message });
        } else {
          resolve({ isValid: false, response, message });
        }
      };

      ChargeOver.ACH.validate(data, callback);
    });

    return result;
  } catch (error) {
    console.log(error);
    return { isValid: false, response: error };
  }
}

const signupCustomerCC = (data) => {
  return new Promise((resolve, reject) => {
    window.ChargeOver.Signup.signup(data, (code, message, response) => {
      console.log('ChargeOver Signup', data);
      if (code === 200) {
        console.log(`Created customer ${response.customer.company}`);
        resolve(response);
      } else {
        console.error(`Failed to create customer: ${message}`);
        reject(new Error(message));
      }
    });
  });
};

const signupCustomerACH = (data) => {
  return new Promise((resolve, reject) => {
    window.ChargeOver.Signup.signup(data, (code, message, response) => {
      console.log('ChargeOver Signup', data);
      if (code === 200) {
        console.log(`Created customer ${response.customer.company}`);
        resolve(response);
      } else {
        console.error(`Failed to create customer: ${message}`);
        reject(new Error(message));
      }
    });
  });
};

module.exports = {
  load,
  signupCustomerCC,
  signupCustomerACH,
  validateCC,
  validateACH
}

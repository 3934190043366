import { firebase } from '@firebase/app'
import '@firebase/database'
import '@firebase/storage'
import 'firebase/functions'
import 'firebase/auth'

const state = {}
const getters = {}
const mutations = {}
const actions = {
  commsSMS ({ commit }, payload) {
    console.log('calling comms sms')
    commit('setLoading', true)
    let rec = {
      toPhoneNos:
      ['+447713587876'
      ],
      message: 'Dont be lazy, get your own groceries.'
  }
    console.log(rec)
    let commsSMS = firebase.functions().httpsCallable('commsSMS')
    return commsSMS(rec)
      .then(data => {
        let resp = data.data.response.data
        console.log('Comms SMS response ', resp)
            console.log(resp)
          })
      .catch(error => {
        console.log(error)
        commit('setLoading', false)
      })
  },
  commsWhatsapp ({ commit }, payload) {
    console.log('calling comms whatsapp')
    commit('setLoading', true)
    let rec = {
      toPhoneNos:
      ['+447713587876'],
      message: 'Your nasty order of rotten eggs has shipped and should be delivered on 1/8/22. Details : N/A'
  }
    console.log(rec)
    let commsWhatsapp = firebase.functions().httpsCallable('commsWhatsapp')
    return commsWhatsapp(rec)
      .then(data => {
        let resp = data.data.response.data
        console.log('Comms whatsapp response ', resp)
            console.log(resp)
          })
      .catch(error => {
        console.log(error)
        commit('setLoading', false)
      })
  },
  commsEmail ({ commit }, payload) {
    commit('setLoading', true)
    const rec = {
      toEmails: payload.emails,
      subject: payload.subject,
      body: payload.body,
    }

    if (payload.templateId) {
      rec.templateId = payload.templateId
    }

    const commsEmail = firebase.functions().httpsCallable('commsEmail')
    return commsEmail(rec)
      .then(data => {
        const resp = data.data.response.data
        console.log('Comms email response ', resp)
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        commit('setLoading', false)
      })
  },
  commsMimeType ({ commit }, payload) {
    console.log('calling comms mime type')
    commit('setLoading', true)
    let rec = {
      filename: 'name.pdf'
  }
    console.log(rec)
    let commsMimeType = firebase.functions().httpsCallable('commsMimeType')
    return commsMimeType(rec)
      .then(data => {
        let resp = data.data.response.data
        console.log('Comms mime type response ', resp)
            console.log(resp)
          })
      .catch(error => {
        console.log(error)
        commit('setLoading', false)
      })
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}

import Vue from 'vue'
import VueI18n from 'vue-i18n'

import ar from 'vuetify/lib/locale/ar'
import en from 'vuetify/lib/locale/en'
import fr from 'vuetify/lib/locale/fr'
import { localize } from 'vee-validate'

Vue.use(VueI18n)

const messages = {
  en: {
    ...require('@/locales/en.json'),
    $vuetify: en,
  },
  fr: {
    ...require('@/locales/fr.json'),
    $vuetify: fr,
  },
  ar: {
    ...require('@/locales/ar.json'),
    $vuetify: ar,
  },
}
export const siteVersion = process.env.VUE_APP_VERSION

const [, defaultLangauge = 'en'] = document.cookie.match(/locale=([a-z]+)/i) || []
localize(defaultLangauge)
export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages,
  silentTranslationWarn: true
})

<template>
  <div>
    <v-dialog
      v-model="errorDialogComputed"
      max-width="700"
      persistent
    >
      <v-card min-height="33vh">
        <v-card-title class="justify-center font-weight-bold text-h5">
          We are unable to process your request
        </v-card-title>
        <v-card-text class="justify-center text-center body primmary--text">
          <v-row>
            <v-col>
              We’re sorry that we are unable to process your application at this time due to the following error. Please contact us for support with your application.
            </v-col>
          </v-row>
          <v-row v-if="errorMessage">
            <v-col>{{ errorMessage }}</v-col>
          </v-row>
        </v-card-text>
        <v-row no-gutters>
          <v-col>
            <div class="d-flex justify-center">
              <v-btn
                v-if="!customErrorMethod"
                color="primary"
                class="mb-4 mt-4 font-weight-bold"
                @click="clearError"
              >
                Return to Home
              </v-btn>
              <v-btn
                v-if="customErrorMethod"
                color="primary"
                class="mb-4 mt-4 font-weight-bold"
                @click="$emit('customErrorCloseEvent')"
              >
                {{ customErrorCloseButton }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: 'ErrorDialog',
    components: {
    },
    props: {
      errorDialog: {
        type: Boolean,
        default: false,
      },
      errorMessage: {
        default: false,
      },
      customErrorMethod: {
        default: false,
      },
      customErrorCloseButton: {
        default: 'Close',
      },
    },
    data: () => ({
    }),
    computed: {
      errorDialogComputed: {
        get () { return this.errorDialog },
        set (value) { this.$emit('input', value) },
      },
    },
    mounted () {
    },
    methods: {
      clearError () {
        this.$store.dispatch('clearError')
        this.errorDialog = false
        this.$router.push({ path: '/' })
      },
    },

  }
</script>

<style lang="scss" scoped>
</style>

import { firebase } from '@firebase/app'
import '@firebase/database'
import '@firebase/storage'
import 'firebase/functions'
import 'firebase/auth'
import ShortUniqueId from 'short-unique-id'
import { v4 as uuidv4 } from 'uuid'
import { compileToFunctions } from 'vue-template-compiler'
const state = {
    borderfree: {
        request_id: '',
        user_ref_id: '',
    },
    BFSessionToken: '',
    BFOrderId: '',
    BFVaultObj: {},
    BFPaymentResponse: {},
    BorderFreeOrderResponse: {},
    BorderFreeToken: {},
}

const getters = {
    getBFSessionToken (state) {
        return state.BFSessionToken
    },
    getBFOrderId (state) {
        return state.BFOrderId
    },
    getVaultObject (state) {
        console.log('state.BFVaultObj', state.BFVaultObj)
        return state.BFVaultObj
  },
  getPaymentResponse (state) {
    return state.BFPaymentResponse
  },
  getBorderFreeOrderResponse (state) {
    return state.BorderFreeOrderResponse
  },
  getBorderFreeToken (state) {
    return state.BorderFreeToken
  }
}

const mutations = {
    setBFSessionToken (state, payload) {
        state.BFSessionToken = payload
    },
    setBFOrderId (state, payload) {
        state.BFOrderId = payload
    },
    setBFVaultObj (state, payload) {
        state.BFVaultObj = payload
    },
    setBFPaymentResponse (state, payload) {
        state.BFPaymentResponse = payload
    },
    setBorderFreeOrderResponse (state, payload) {
        state.BorderFreeOrderResponse = payload
    },
    setBorderFreeToken (state, payload) {
        state.BorderFreeToken = payload
    }
}
const actions = {
    setBFPaymentResponse ({ commit }, payload) {
        commit('setBFPaymentResponse', payload)
    },
    setBFSessionToken ({ commit }, payload) {
        commit('setBFSessionToken', payload)
    },
    async borderfreeCreateOrder ({ commit, getters }, payload) {
        let user = payload.user
        console.log('user', user)
        commit('setLoading', true)
        let rec = {
            instrument_token: {
                payment_method: 'CARD',
              },
            request_id: uuidv4(),
            customer_name: user.firstname,
            account: {
                user_ref_id: getters.getuser.id,
                first_name: user.firstname,
                last_name: user.lastname,
                email: user.email,
                phoneno: user.phone,
                callingCode: '+1',
                address: {
                    type: user.propertyType,
                    city: user.address.city,
                    country: 'USA',
                    label: 'profile',
                    line1: user.address.addr1,
                    postal_code: user.address.postalCode,
                    state_code: user.address.stateProvCd
                },
                is_create_bpay_account: true
            },
            merchant_order_id: uuidv4(),
            customer_meta_data: {
                event_id: '111111'
            },
            txn_code: 41,
            currency: 'USD',
            product_origin: 'US',
            merchant_redirect_url: 'https://applywithblanket.com/redirect',
            merchant_webhook_url: 'https://applywithblanket.com/webhook',
            countrycode: 'US',
            order: {
                line_items: [{
                    product_id: 'productId1',
                    variant_id: 'variantId1',
                    uid: uuidv4(),
                    name: 'insurance policy',
                    quantity: '1',
                    base_amount: payload.transactionDetails.order.line_items[0].base_amount,
                    description: payload.transactionDetails.order.line_items[0].description
                }],
                contact_details: {
                    email: user.email,
                    first_name: user.firstname,
                    last_name: user.lastname,
                    phone_number: user.phone
                },
                shipping_address: {
                  city: 'New York',
                  country: 'United States',
                  country_code: 'US',
                  email: 'bob@test.com',
                  first_name: 'Bob',
                  last_name: 'Buttons',
                  phone_number: '+12024561111',
                  postal_code: '10001',
                  line1: '63 Heather Road',
                  state: 'New York',
                  state_code: 'NY',
                },
            }
        }
        console.log(rec)
        let borderfreeCreateOrder = firebase.functions().httpsCallable('borderfreeCreateOrder')
        return borderfreeCreateOrder(rec)

      .then((data) => {
        console.log(data)
        console.log('borderfree create order response', data.data.response)
        let resp = data.data.response
        console.log(data.data.response.session_token.token)
        console.log('ORDER!', resp.data)
        commit('setBorderFreeOrderResponse', resp.data)
        commit('setBFOrderId', resp.data.order_id)
        commit('setBFSessionToken', resp.session_token.token)
        commit('setLoading', false)
      })
      .catch(
        (error) => {
          console.log(error)
          commit('setLoading', false)
        }
      )
},

async updateVault ({ commit, getters, state }, payload) {
    commit('setLoading', true)
    let rec = {
        userkey: getters.getuser.id,
        tnxData: payload
    }
    console.log(rec)
    let updateVault = firebase.functions().httpsCallable('updateVault')
    return updateVault(rec)
  .then((data) => {
    console.log(data)
    console.log('borderfree update vault response', data.data.response)
    let resp = data.data.response
    console.log(resp)
    commit('setBorderFreeToken', resp.data.instrument_token.token)
    commit('setLoading', false)
  })
  .catch(
    (error) => {
      console.log(error)
      commit('setLoading', false)
    }
  )
},

async vaultPayment ({ commit, state }, payload) {
  commit('setLoading', true)
  let rec = {
    vault_token: 'tok_75564834074818775301600338674',
    order: {
        line_items: [
            {
                quantity: '1',
                base_amount: '999.83'
            }
        ]
    },
    account: {
        user_ref_id: '2WYh1dN0HdVGCAzIDIVkPWQ4GjI3'
    }
}
  console.log(rec)
  let updateVault = firebase.functions().httpsCallable('updateVault')
  return updateVault(rec)
.then((data) => {
  console.log(data)
  let resp = data.data.response
  console.log('vault payment resp', resp)
  commit('setLoading', false)
})
.catch(
  (error) => {
    console.log(error)
    commit('setLoading', false)
  }
)
},
}

      export default {
        state,
        getters,
        actions,
        mutations
      }

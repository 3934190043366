import i18n from '../../i18n'

const defaultState = () => ({
  addressKeyword: '',
  caTermAddresses: [],
  currentAddress: '',
  validateAddressError: '',
  isAddressValidated: false,
  isAddressApiDown: false
})

const state = defaultState()

const KEY = process.env.VUE_ADDRESS_VALIDATION_KEY || 'JY94-GZ94-KF41-GG59'
const getters = {
  getAddressKeyword: (state) => state.addressKeyword,
  getCaTermAddreses: (state) => state.caTermAddresses,
  getCurrentAddress: (state) => state.currentAddress,
  getValidateAddressError: (state) => state.validateAddressError,
  getIsAddressValidated: (state) => state.isAddressValidated,
  getAddressApiDown: (state) => state.isAddressApiDown,
}

const mutations = {
  setAddressKeyword (state, value) {
    state.addressKeyword = value
  },
  setCaTermAddresses (state, value) {
    state.caTermAddresses = value
  },
  setCurrentAddress (state, value) {
    state.currentAddress = value
  },
  setValidationAddressError (state, value) {
     state.validateAddressError = value
  },
  setIsAddressValidated (state, value) {
    state.isAddressValidated = value
  },
  setAddressApiDown (state, value) {
    state.isAddressApiDown = value
  }
}
const actions = {
  async searchAddress ({ commit, state }) {
      try {
        commit('setLoading', true)
        const allowedProvinces = ['ON', 'QC', 'AB', 'BC']
        const response = await fetch(`https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Find/v2.10/json3ex.ws?Key=${KEY}&SearchTerm=${state.addressKeyword}&Country=CA&Province=${allowedProvinces}`)
        const data = await response.json()
        if (data?.Items?.length > 0) {
          const filteredAddresses = data?.Items.filter(address => {
          const provinceCode = address?.Description?.split(', ')[1]
            return allowedProvinces.includes(provinceCode)
          })
          console.log('data', filteredAddresses)
          commit('setCaTermAddresses', filteredAddresses)
          commit('setLoading', false)
          commit('setAddressApiDown', false)
          commit('setValidationAddressError', '')
        } else {
          commit('setLoading', false)
          commit('setCaTermAddresses', [])
          commit('setValidationAddressError', i18n.t('addressNotFound'))
        }
      } catch (error) {
        console.error('Error fetching addresses:', error)
        commit('setAddressApiDown', true)
        commit('setValidationAddressError', i18n.t('apiDown'))
        commit('setCaTermAddresses', [])
        commit('setLoading', false)
      }
  },
  selectAddress ({ commit, dispatch }, address) {
    commit('setCurrentAddress', address)
  },
  async validateAddress ({ commit, state }, address) {
    commit('setLoading', true)
    let recordFound = false
    try {
      const response = await fetch(`https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Find/v2.10/json3ex.ws?Key=${KEY}&SearchTerm=${address.addr1}&Country=CA`)
      const data = await response.json()
      commit('setAddressApiDown', false)
      if (data?.Items?.length > 0 && !data?.Items[0]?.Error) {
        const resultSet = data?.Items || []
        for (const item of resultSet) {
          const description = item?.Description
          const addr = item?.Text
          if (description) {
            const [city, province, zipcode] = description.split(',').map(field => field.trim())
            if (address.city === city &&
              address.province === province &&
              address.postalCode === zipcode &&
              address.addr1 === addr
              ) {
              recordFound = true
              break
            }
          }
        }
        if (recordFound) {
          commit('setIsAddressValidated', true)
          commit('setValidationAddressError', '')
        } else {
          commit('setIsAddressValidated', false)
          commit('setValidationAddressError', i18n.t('addressValidationError'))
        }
      } else {
        commit('setIsAddressValidated', false)
        commit('setValidationAddressError', i18n.t('addressValidationError'))
      }
      commit('setLoading', false)
      commit('setAddressApiDown', false)
    } catch (error) {
      commit('setAddressApiDown', true)
      commit('setLoading', false)
      commit('setIsAddressValidated', true)
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}

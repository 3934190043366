<template>
  <component
    :is="component"
    :progress="100"
    color="#00A1B7"
    empty-color="transparent"
    :size="size"
    :thickness="7"
    :empty-thickness="3"
    line-mode="out 5"
    :legend="false"
    animation="rs 700 1000"
    font-size="1.5rem"
    :loading="loading"
    :empty-color-fill="emptyColorFill"
  >
    <img
      slot="legend-caption"
      height="150px"
      :src="require(`@/assets/images/${filename}`)"
    />
  </component>
</template>
<script>
  import { VueEllipseProgress } from 'vue-ellipse-progress'

  export default {
    name: 'EllipseProgress',
    components: { VueEllipseProgress },
    props: ['loading', 'progress', 'size', 'thickness', 'emptyThickness', 'filename', 'color', 'emptyColor', 'emptyColorFill'],
    data () {
      return {
      }
    },
    computed: {
      component () {
        return 'vue-ellipse-progress'
      }
    }
  }
</script>

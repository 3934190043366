/* eslint-disable no-console */

import { register } from 'register-service-worker'
register(`${process.env.BASE_URL}service-worker.js`, {

  ready () {

  },
  registered () {

  },
  cached () {

  },
  updatefound () {

  },
  updated () {

  },
  offline () {
    console.log('No internet connection found. App is running in offline mode.')
  },
  error (error) {
    console.error('Error during service worker registration:', error)
  }
})

<template>
  <div>
    <v-dialog
      v-model="ineligibleDialog"
      max-width="700"
    >
      <v-card min-height="33vh">
        <v-card-title class="justify-center font-weight-bold text-h5">
          {{ $t('sorry') }}
        </v-card-title>
        <v-card-text class="justify-center text-center">
          <v-row>
            <v-col>
              {{ $t('canadianCitizen') }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-row no-gutters>
          <v-col>
            <div class="d-flex justify-center">
              <v-btn
                color="primary"
                class="mb-4 mt-4 font-weight-bold"
                @click="handleClose"
              >
                {{ $t('close') }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    props: {
      ineligibleDialog: {
        type: Boolean,
        required: true
      }
    },
    methods: {
      handleClose () {
        this.$emit('closeInEligibleDialog')
      }
    }
  }
</script>

import Vue from 'vue'
import { extend } from 'vee-validate'

// import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'

// import { ValidationObserver, extend } from 'vee-validate'

import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'

extend('customBefore', {
    params: ['target'],
    validate (value, { target }) {
        return value < target
    },
    message: 'Start date should be before end date'
})

extend('credit_card', {
  validate: (value, { cardType }) => {
    switch (cardType) {
      case 'Vi':
        if (/^(4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14})$/.test(value)) {
          if (/^4[0-9]{12}(?:[0-9]{3})?$/.test(value) || /^5[1-5][0-9]{14}$/.test(value)) {
            return true
          }
        }
        break
      case 'AM':
        if (/^3[47][0-9]{13}$/.test(value)) {
          return true
        }
        break
      case 'Di':
        if (/^6(?:011|5[0-9]{2})[0-9]{12}$/.test(value)) {
          return true
        }
        break
    }
    return false
  },
  params: ['cardType'],
  message: 'Card Number is not valid'
})
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

// idleDetectionPlugin.js
const IdleDetection = {
  install (Vue, { idleTime = 15 * 60 * 1000 } = {}) {
    const eventsList = ['mousemove', 'keydown', 'mousedown', 'scroll', 'touchstart', 'touchmove', 'touchend']
    const idleDetection = {
      isIdle: false,
      idleTimeout: null,
      onIdleCallbacks: [],
      onActiveCallbacks: [],
      resetIdleTimer: null,
      startIdleDetection () {
        const resetIdleTimer = () => {
          clearTimeout(idleDetection.idleTimeout)
          if (idleDetection.isIdle) {
            idleDetection.isIdle = false
            idleDetection.onActiveCallbacks.forEach(cb => cb())
          }
          idleDetection.idleTimeout = setTimeout(() => {
            idleDetection.isIdle = true
            idleDetection.onIdleCallbacks.forEach(cb => cb())
          }, idleTime)
        }
        idleDetection.resetIdleTimer = resetIdleTimer
        eventsList.forEach((event) => {
          window.addEventListener(event, resetIdleTimer)
        })
        resetIdleTimer()
      },
      stopIdleDetection () {
        eventsList.forEach((event) => {
          window.removeEventListener(event, idleDetection.resetIdleTimer)
        })
        idleDetection.isIdle = false
        clearTimeout(idleDetection.idleTimeout)
      },
      onIdle (callback) {
        idleDetection.onIdleCallbacks.push(callback)
      },
      onActive (callback) {
        idleDetection.onActiveCallbacks.push(callback)
      }
    }

    Vue.prototype.$idleDetection = idleDetection

    Vue.mixin({
      beforeDestroy () {
        if (this.$idleDetection) {
          this.$idleDetection.stopIdleDetection()
        }
      },
      created () {
        if (this.$idleDetection) {
          this.$idleDetection.startIdleDetection()
        }
      }
    })
  }
}

export default IdleDetection

import { firebase } from '@firebase/app'
import '@firebase/database'
import '@firebase/storage'
import 'firebase/functions'
import 'firebase/auth'
// import { saveAs } from 'file-saver'

const state = {
}

const getters = {
}
const mutations = {
}
const actions = {
  async getTrupanionPolicy ({ commit, getters, dispatch }, payload) {
    commit('setLoading', true)
    let user = getters.user
    let userkey = user.id
    let rec = {
      userkey: userkey,
      policyNum: payload.policyNum
      }
    let getTrupanionPolicy = firebase.functions().httpsCallable('getTrupanionPolicy')
    console.log('getTrupanionPolicy REC ', rec)
    return getTrupanionPolicy(rec)
      .then((data) => {
        console.log('getTrupanionPolicy DATA', data)
        // console.log('data.data.response', data.data.response)
        if (data.data && data.data.code === 200) {
          let pdfContent = data.data.response.content
          let fileName = data.data.response.filename
          const linkSource = `data:application/pdf;base64,${pdfContent}`
          const downloadLink = document.createElement('a')
          downloadLink.href = linkSource
          downloadLink.download = fileName
          downloadLink.click()
          downloadLink.remove()
        }
        commit('setLoading', false)
      })
      .catch(
        (error) => {
          commit('setError', 'Unable to process request')
          console.log(error)
          commit('setLoading', false)
        }
      )
  },
  async sendTrupanionPolicy ({ commit, getters, dispatch }, payload) {
    commit('setLoading', true)
    let user = getters.user
    let userkey = user.id
    let rec = {
      userkey: userkey,
      policyNum: payload.policyNum
      }
    let sendTrupanionPolicyTest = firebase.functions().httpsCallable('sendTrupanionPolicy')
    console.log('sendTrupanionPolicy REC ', rec)
    return sendTrupanionPolicyTest(rec)
      .then((data) => {
        console.log('sendTrupanionPolicy DATA', data)
        commit('setLoading', false)
      })
      .catch(
        (error) => {
          commit('setError', 'Unable to process request')
          console.log(error)
          commit('setLoading', false)
        }
      )
  },
  async getStillwaterPolicy ({ commit, getters, dispatch }, payload) {
    commit('setLoading', true)
    let user = getters.user
    let userkey = user.id
    let rec = {
      userkey: userkey,
      policyNum: payload.policyNum
      }
    let getStillwaterPolicy = firebase.functions().httpsCallable('getStillwaterPolicy')
    console.log('getStillwaterPolicy REC ', rec)
    return getStillwaterPolicy(rec)
      .then((data) => {
        console.log('getStillwaterPolicy DATA', data)
        // console.log('data.data.response', data.data.response)
        if (data.data && data.data.code === 200) {
          let pdfContent = data.data.response.content
          let fileName = data.data.response.filename
          const linkSource = `data:application/pdf;base64,${pdfContent}`
          const downloadLink = document.createElement('a')
          downloadLink.href = linkSource
          downloadLink.download = fileName
          downloadLink.click()
          downloadLink.remove()
        }
        commit('setLoading', false)
      })
      .catch(
        (error) => {
          commit('setError', 'Unable to process request')
          console.log(error)
          commit('setLoading', false)
        }
      )
  },
  async sendStillwaterPolicy ({ commit, getters, dispatch }, payload) {
    commit('setLoading', true)
    let user = getters.user
    let userkey = user.id
    let rec = {
      userkey: userkey,
      policyNum: payload.policyNum
      }
    let sendStillwaterPolicy = firebase.functions().httpsCallable('sendStillwaterPolicy')
    console.log('sendStillwaterPolicy REC ', rec)
    return sendStillwaterPolicy(rec)
      .then((data) => {
        console.log('sendStillwaterPolicy DATA', data)
        commit('setLoading', false)
      })
      .catch(
        (error) => {
          commit('setError', 'Unable to process request')
          console.log(error)
          commit('setLoading', false)
        }
      )
  },

  async getGeneraliPolicy ({ commit, getters, dispatch }, payload) {
    commit('setLoading', true)
    let user = getters.user
    let userkey = user.id
    let rec = {
      userkey: userkey,
      policyNum: payload.policyNum
      }
    let getGeneraliPolicy = firebase.functions().httpsCallable('getGeneraliPolicy')
    console.log('getGeneraliPolicy REC ', rec)
    return getGeneraliPolicy(rec)
      .then((data) => {
        console.log('getGeneraliPolicy DATA', data)
        // console.log('data.data.response', data.data.response)
        if (data.data && data.data.code === 200) {
          let pdfContent = data.data.response.content
          let fileName = data.data.response.filename
          const linkSource = `data:application/pdf;base64,${pdfContent}`
          const downloadLink = document.createElement('a')
          downloadLink.href = linkSource
          downloadLink.download = fileName
          downloadLink.click()
          downloadLink.remove()
        }
        commit('setLoading', false)
      })
      .catch(
        (error) => {
          commit('setError', 'Unable to process request')
          console.log(error)
          commit('setLoading', false)
        }
      )
  },

  async sendGeneraliPolicy ({ commit, getters, dispatch }, payload) {
    commit('setLoading', true)
    let user = getters.user
    let userkey = user.id
    let rec = {
      userkey: userkey,
      policyNum: payload.policyNum
      }
    let sendGeneraliPolicy = firebase.functions().httpsCallable('sendGeneraliPolicy')
    console.log('sendGeneraliPolicy REC ', rec)
    return sendGeneraliPolicy(rec)
      .then((data) => {
        console.log('sendGeneraliPolicy DATA', data)
        commit('setLoading', false)
      })
      .catch(
        (error) => {
          commit('setError', 'Unable to process request')
          console.log(error)
          commit('setLoading', false)
        }
      )
  },

  async getTugoPolicy ({ commit, getters, dispatch }, payload) {
    commit('setLoading', true)
    let user = getters.user
    let userkey = user.id
    let rec = {
      userkey: userkey,
      policyNum: payload.policyNum
      }
    let getTugoPolicy = firebase.functions().httpsCallable('getTugoPolicy')
    console.log('getTugoPolicy REC ', rec)
    return getTugoPolicy(rec)
      .then((data) => {
        console.log('getTugoPolicy DATA', data)
        // console.log('data.data.response', data.data.response)
        if (data.data && data.data.code === 200) {
          let pdfContent = data.data.response.content
          let fileName = data.data.response.filename
          const linkSource = `data:application/pdf;base64,${pdfContent}`
          const downloadLink = document.createElement('a')
          downloadLink.href = linkSource
          downloadLink.download = fileName
          downloadLink.click()
          downloadLink.remove()
        }
        commit('setLoading', false)
      })
      .catch(
        (error) => {
          commit('setError', 'Unable to process request')
          console.log(error)
          commit('setLoading', false)
        }
      )
  },

  async sendTugoPolicy ({ commit, getters, dispatch }, payload) {
    commit('setLoading', true)
    let user = getters.user
    let userkey = user.id
    let rec = {
      userkey: userkey,
      policyNum: payload.policyNum
      }
    let sendTugoPolicy = firebase.functions().httpsCallable('sendTugoPolicy')
    console.log('sendTugoPolicy REC ', rec)
    return sendTugoPolicy(rec)
      .then((data) => {
        console.log('sendTugoPolicy DATA', data)
        commit('setLoading', false)
      })
      .catch(
        (error) => {
          commit('setError', 'Unable to process request')
          console.log(error)
          commit('setLoading', false)
        }
      )
  },

  async getAfficiencyPolicy ({ commit, getters, dispatch }, payload) {
    commit('setLoading', true)
    let user = getters.user
    let userkey = user.id
    let rec = {
      userkey: userkey,
      policyNum: payload.policyNum
      }
    let getAfficiencyPolicy = firebase.functions().httpsCallable('getAfficiencyPolicy')
    console.log('getAfficiencyPolicy REC ', rec)
    return getAfficiencyPolicy(rec)
      .then((data) => {
        console.log('getAfficiencyPolicy DATA', data)
        // console.log('data.data.response', data.data.response)
        if (data.data && data.data.code === 200) {
          let pdfContent = data.data.response.content
          let fileName = data.data.response.filename
          const linkSource = `data:application/pdf;base64,${pdfContent}`
          const downloadLink = document.createElement('a')
          downloadLink.href = linkSource
          downloadLink.download = fileName
          downloadLink.click()
          downloadLink.remove()
        }
        commit('setLoading', false)
      })
      .catch(
        (error) => {
          commit('setError', 'Unable to process request')
          console.log(error)
          commit('setLoading', false)
        }
      )
  },

  async sendAfficiencyPolicy ({ commit, getters, dispatch }, payload) {
    commit('setLoading', true)
    let user = getters.user
    let userkey = user.id
    let rec = {
      userkey: userkey,
      policyNum: payload.policyNum
      }
    let sendAfficiencyPolicy = firebase.functions().httpsCallable('sendAfficiencyPolicy')
    console.log('sendAfficiencyPolicy REC ', rec)
    return sendAfficiencyPolicy(rec)
      .then((data) => {
        console.log('sendAfficiencyPolicy DATA', data)
        commit('setLoading', false)
      })
      .catch(
        (error) => {
          commit('setError', 'Unable to process request')
          console.log(error)
          commit('setLoading', false)
        }
      )
  },

}

export default {
  state,
  getters,
  mutations,
  actions
}

import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/i18n'
import '@/sass/overrides.sass'

Vue.use(Vuetify)

const theme = {
  primary: '#00A1B7',
  blanketgrey: '#757575',
  secondary: '#005F6C',
  travel: '#46C5D6',
  accent: '#D8FDD7;',
  info: '#00CAE3',
  pet: '#FF8A80',
  tugo: '#40C4FF',
  term: '#EA80FC',
  blanketv2accent: '#005F6C',
  blanketcolor: '#009db3',
  blanketbutton: '#009db3',
  blanketbluebackground: '#DEECF1',
  blanketsecondary: '#005F6C',
  blanketaccent: '#CBF9D3',
  blankettwhite: '#F5F1E8',
  blanketfooter: '#005f6c',
  blanketbackground: '#f9fafc',
  mint: '#AAF0C1',
  darkgrey: '#292F36'
}

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
})

export default {
  install (Vue) {
    Vue.prototype.$Storage = {
      setCookie (key, value) {
        document.cookie = `${key}=${value};path=/`
      },
      getCookie (name) {
        const item = document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)
        return item ? item.pop() : ''
      }
    }
  },
}

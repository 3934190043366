// all about creating user accounts
import { firebase } from '@firebase/app'
import '@firebase/database'
import '@firebase/storage'
import 'firebase/functions'
import 'firebase/auth'

const state = {
  checkingUserkey: false,
  loggingProcess: false,
  currentuserkey: ''
}
const getters = {
  getlogginginProcess (state) {
    return state.loggingProcess
  },
}
const mutations = {
  setlogginginuser (payload) {
    state.loggingProcess = payload
  },
}
const actions = {
  loginforpurchase ({ commit }, payload) {
    let email = payload.email
    let pass = payload.password
    // format phone to have only numbers
    console.log('login account for user ')
    // return error message
    // or return profile key value
  },
  checkaccount ({ commit }, payload) {
    let email = payload
    let checkAccountKey = firebase.functions().httpsCallable('getAccountKeybyEmail')
    return checkAccountKey(email)
      .then((data) => {
        console.log('response from check account key ', data)
        if (data.data.response) {
          if (data.data.response.code === 200) {
            console.log('all found ', data.data.response.response.uid)
          } else if (data.data.response.code === 404) {
            console.log('not found ', data.data.response.response)
          }
        }
       })
  }
}

export default {
    state,
    getters,
    actions,
    mutations
  }

// plugins/google-tag-manager.js

function loadGTM (id) {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js'
  })

  const f = document.getElementsByTagName('script')[0]
    const j = document.createElement('script')
  // eslint-disable-next-line no-constant-condition,no-undef,no-self-compare
    const dl = 'dataLayer' !== 'dataLayer' ? '&l=' + dataLayer : ''
  j.async = true
  j.src = 'https://www.googletagmanager.com/gtm.js?id=' + id + dl
  f.parentNode.insertBefore(j, f)
}

export default {
  install (Vue, options) {
    Vue.prototype.$gtag = (data) => {
      window.dataLayer.push(data)
    }

    // Load GTM with your specific ID
    loadGTM(options.id)
  }
}

import { firebase } from '@firebase/app'
import '@firebase/database'
import '@firebase/storage'
import 'firebase/functions'
import 'firebase/auth'

const parseString = require('xml2js').parseString

const state = {
  stillwaterLossTypes: [],
  stillwaterLossCodes: [],
  homeYearBuilt: '',
  customHomeQuote: null,
  homeSurveyQuestions: [],
  homeowner: {},
  homeQuote: {},
  homePropertyData: {},
  standardHomeQuotes: [],
  deluxeHomeQuotes: [],
  smallHomeQuoteH3: {},
  mediuHomelQuoteH3: {},
  largeHomeQuoteH3: {},
  smallHomeQuoteH5: {},
  mediuHomelQuoteH5: {},
  largeHomeQuoteH5: {},
  smallHomeQuoteNumber: '',
  mediumHomeQuoteNumber: '',
  largeHomeQuoteNumber: '',
  homeDwellUses: [{ text: 'Primary, (nonseasonal)', code: 1 }, { text: 'Primary, Seasonal', code: 3 }, { text: 'Secondary, (non-seasonal) ', code: 3 }, { text: 'Seasonal, (secondary)', code: 4 }, { text: 'Farm', code: 6 }, { text: 'Vacant', code: 7 }, { text: 'Occasionally Occupied', code: 8 }, { text: 'Other', code: 'OT' }]
}

const getters = {
  getHomeDwellUses: state => {
    return state.homeDwellUses
  },
  getHomeTypes (state) {
    return state.homeTypes
  },
  getHomeQuotes (state) {
    return state.homwQuotes
  },
  getSmallHomeQuoteH3 (state) {
    return state.smallHomeQuoteH3
  },
  getSmallHomeQuoteH5 (state) {
    return state.smallHomeQuoteH5
  },
  getMediumHomeQuoteH3 (state) {
    return state.mediumHomeQuoteH3
  },
  getMediumHomeQuoteH5 (state) {
    return state.mediumHomeQuoteH5
  },
  getLargeHomeQuoteH3 (state) {
    return state.largeHomeQuoteH3
  },
  getLargeHomeQuoteH5 (state) {
    return state.largeHomeQuoteH5
  },
  getHomeQuote (state) {
    return state.homeQuote
  },
  getHomeowner (state) {
    return state.homeowner
  },
  getHomeSurveyQuestions (state) {
    return state.homeSurveyQuestions
  },
  getCustomHomeQuote (state) {
    return state.customHomeQuote
  },
  getHomePropertyData (state) {
    return state.homePropertyData
  },
  getHomeYearBuilt (state) {
    return state.homeYearBuilt
  },
  getStillwaterLossTypes (state) {
    return state.stillwaterLossTypes
  },
  getStillwaterLossCodes (state) {
    return state.stillwaterLossCodes
  }
}
const mutations = {
  setHomeQuotes (state, payload) {
    state.homeQuotes.push(payload)
  },
  setSmallHomeQuoteH3 (state, payload) {
    state.smallHomeQuoteH3 = payload
  },
  setMediumHomeQuoteH3 (state, payload) {
    state.mediumHomeQuoteH3 = payload
  },
  setLargeHomeQuoteH3 (state, payload) {
    state.largeHomeQuoteH3 = payload
  },
  setSmallHomeQuoteH5 (state, payload) {
    state.smallHomeQuoteH5 = payload
  },
  setMediumHomeQuoteH5 (state, payload) {
    state.mediumHomeQuoteH5 = payload
  },
  setLargeHomeQuoteH5 (state, payload) {
    state.largeHomeQuoteH5 = payload
  },
  setHomeQuote (state, payload) {
    state.homeQuote = payload
  },
  setHomeowner (state, payload) {
    state.homeowner = payload
  },
  setHomeSurveyQuestions (state, payload) {
    state.homeSurveyQuestions = payload
  },
  setCustomHomeQuote (state, payload) {
    state.customHomeQuote = payload
  },
  clearCustomHomeQuote (state) {
    state.customHomeQuote = null
  },
  setHomePropertyData (state, payload) {
    state.homePropertyData = payload
  },
  setHomeYearBuilt (state, payload) {
    state.homeYearBuilt = payload
  },
  setStillwaterLossTypes (state, payload) {
    state.stillwaterLossTypes = payload
  },
  setStillwaterLossCodes (state, payload) {
    state.stillwaterLossCodes = payload
  },
}
const actions = {
  async getInitialHomeQuote ({ commit, getters }, payload) {
    console.log('Calling homeowners quote')
    commit('setLoading', true)
    let rec = {
      nonCA: false,
        signonRq:
      {
        custLangPref: 'ENG'
      },
        insuranceSvcRq:
      {
            quoteOrBuy: 'Q',
        homePolicyRq:
        {
          curCd: 'USD',
          insuredOrPrincipals:
          [
            {
              generalPartyInfo:
              {
                nameInfo:
                {
                  personName:
                  {
                    surname: 'BROWN',
                    givenName: 'DAVID'
                  }
                },
                communications:
                {
                  phoneInfo:
                  {
                    phoneTypeCd: 'Phone',
                    phoneNumber: '+1-402-321-6300'
                  },
                  emailInfo:
                  {
                    emailAddr: 'nadav@blanket.com'
                  }
                }
              },
              insuredOrPrincipalInfo:
              {
                insuredOrPrincipalRoleCd: 'FNI',
                personInfo:
                {
                  birthDt: '1955-02-10',
                }
              }
            },
          ],
          persPolicy:
          {
                    LOBCd: 'HOME',
            contractTerm:
            {
              effectiveDt: '2022-02-25'
            },
            numLosses: 0,
            persApplicationInfo:
            {
              residenceOwnedRentedCd: 'OWNED'
            }
          },
          location:
          {
            id: 'LOC1',
            itemIdInfo:
            {
              agencyId: '12345'
            },
            addr:
            {
              addrTypeCd: 'PhysicalRisk',
              addr1: payload.addr.addr1,
              city: payload.addr.city,
              stateProvCd: payload.addr.stateProvCd,
              postalCode: payload.addr.postalCode,
            }
          },
          homeLineBusiness:
          {
            LOBCd: 'HOME',
            dwell:
            {
              id: 'DWE1',
              locationRef: 'LOC1',
              policyTypeCd: payload.policyTypeCd,
              construction:
              {
                yearBuilt: '1984',
                constructionCd: 'F',
              },
              dwellOccupancy:
              {
                residenceTypeCd: 'DW',
                dwellUseCd: 1,
                occupancyTypeCd: 'OWNER'
              },
              coverages:
              [
                {
                  coverageCd: 'DWELL',
                                deductible:
                                {
                                    formatInteger: payload.deductible,
                                    deductibleAppliesToCd: 'AllPeril'
                                }
                },
              ],
            }
          }
        }
      }
    }
    let getHomeQuote = firebase.functions().httpsCallable('homeQuote')
    return getHomeQuote(rec)
    .then(data => {
      console.log(payload.type + payload.policyTypeCd, rec)
      let resp = data.data.response.data
      parseString(resp, function (err, result) {
        if (result) {
          if (payload.type === 'small' && payload.policyTypeCd === '03') {
            commit('setSmallHomeQuoteH3', result)
          } else if (payload.type === 'medium' && payload.policyTypeCd === '03') {
            commit('setMediumHomeQuoteH3', result)
          } else if (payload.type === 'large' && payload.policyTypeCd === '03') {
            commit('setLargeHomeQuoteH3', result)
          } else if (payload.type === 'small' && payload.policyTypeCd === '05') {
            commit('setSmallHomeQuoteH5', result)
          } else if (payload.type === 'medium' && payload.policyTypeCd === '05') {
            commit('setMediumHomeQuoteH5', result)
          } else if (payload.type === 'large' && payload.policyTypeCd === '05') {
            commit('setLargeHomeQuoteH5', result)
          }
          console.log(result)
          commit('setLoading', false)
        } else {
          console.log(err)
          commit('setLoading', false)
        }
      })
    })
    .catch(error => {
      console.log(error)
      commit('setLoading', false)
    })
  },
  async propertyLookup ({ commit, getters }, payload) {
    commit('setLoading', true)
    let rec = {
      signonRq: {
        custLangPref: 'ENG'
      },
      addr: {
        addr1: payload.addr1,
        city: payload.city,
        stateProvCd: payload.stateProvCd,
        postalCode: payload.postalCode
      }
    }
    console.log(rec)
    let propertyLookup = firebase.functions().httpsCallable('propertyLookup')
    return propertyLookup(rec)
      .then(data => {
        let resp = data.data.response.data
        console.log('Stillwater property lookup response ', resp)
        parseString(resp, function (err, result) {
          if (result) {
            console.log(result)
            commit('setHomeYearBuilt', '1984')
            commit('setLoading', false)
          } else {
            console.log(err)
            commit('setLoading', false)
          }
          console.log('Property Data', getters.getHomePropertyData)
        })
      })
      .catch(error => {
        console.log(error)
        commit('setLoading', false)
      })
  },
  async getStillwaterLossCodes ({ commit }, payload) {
    console.log(payload)
    return firebase.database().ref('stillwater').child('refdata').child('Loss').child('Codes').once('value')
    .then((data) => {
      console.log('Loss Codes', data.val())
      let values = Object.values(data.val())
      console.log('VALUES', values)
      commit('setStillwaterLossCodes', values)
    })
    .catch(
      (error) => {
        console.log(error)
      }
    )
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
